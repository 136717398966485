import React from "react";
import { Row, Container, Form, Button } from "react-bootstrap";
import "./Footer.css";
import * as Fa from "react-icons/fa";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";

function Footer() {
  return (
    <>
      <div className="foo">
        <div className="form-top">
          <Container>
            <a href="/">
              <img
                className="footer-logo"
                src={require("../../assets/logo_main2.png")}
              ></img>
            </a>
            <Row>
              <div className="col-lg-8 col-md-8 col-sm-8 footer1">
                <Row>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="address">
                      <p>
                        <i>
                          <Fa.FaLocationArrow />
                        </i>{" "}
                        49 Arnold Road, Claton-on-sea, CO15 1DE, UK.
                      </p>
                      <p>
                        <i>
                          <Bs.BsFillTelephoneFill />
                        </i>{" "}
                        01255 448888
                      </p>
                      <p>
                        <i>
                          <Bs.BsFillTelephonePlusFill />
                        </i>{" "}
                        07944 126272 <span>(Admission Team)</span>
                      </p>
                      <p>
                        <i>
                          <Ai.AiFillMail />
                        </i>{" "}
                        Jennifer@nesthealthcare.co.uk
                      </p>
                    </div>
                    <div class="f_social d-flex">
                      <i className="fb">
                        <a href="https://www.facebook.com/" target="_blank"><Fa.FaFacebookF/></a>
                      </i>
                      <i className="yt">
                        <a href="https://www.youtube.com/" target="_blank"><Bs.BsYoutube/></a>
                      </i>
                      <i className="insta">
                        <a href="https://www.instagram.com/" target="_blank"><Ai.AiFillInstagram/></a>
                      </i>
                      <i className="twt">
                        <a href="https://www.twitter.com/" target="_blank"><Bs.BsTwitter/></a>
                      </i>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <h4 class="footer_sub-head">Information</h4>
                    <ul className="footer_ul">
                      <li className="footer_links">
                        <a href="/therapies">Therapies</a>
                      </li>
                      {/* <li className="footer_links">
                        <a href="/">Instructors</a>
                      </li> */}
                      <li className="footer_links">
                        <a href="/about">About Us</a>
                      </li>
                      <li className="footer_links">
                        <a href="/">Terms & Conditions</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2">
                    <h4 class="footer_sub-head">Support</h4>
                    <ul className="footer_ul">
                      <li className="footer_links">
                        <a href="/">FAQ</a>
                      </li>
                      <li className="footer_links">
                        <a href="/">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </Row>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 footer2">
                <p>Get the freshest news immedietly</p>
                <div className="subscribe d-flex">
                  <input type="mail" name="" id="" placeholder="your mail address here"/>
                  <button>subscribe</button>
                </div>
              </div>
            </Row>
          </Container>
        </div>
        <div className="footer-btm">
          <div class="foo_line"></div>
          <Container>
            <p>
              Designed & Developed By Ferns-IT
            </p>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Footer;
