import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import TopBar from "../TopBar/TopBar";
import "./ProfileHeader.css";

function ProfileHeader() {
  const location = useLocation();   
  const [isUrl, setIsUrl] = useState(null);
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  
  const changeNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setNavbar(true) : setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);

  useEffect(() => {
    setIsUrl(location.pathname);
  }, [location]);

  return (
    <>
      <TopBar />
      <div className={navbar ? "main-construc scrolled" : "main-construc"}>
        <div class="container d-flex">
          <div class="logo_area">
            <a href="/">
              <img src={require("../../assets/logo-web.png")} alt="" />
            </a>
          </div>

            <>
              <nav className="nav-bar">
                <ul className={click ? "profile_header active" : "profile_header"}>
                  {/* <li>
                    <Link
                      exact={true}
                      to="/"
                      className={
                        "profile_header" + (isUrl === "/" ? " selected" : "")
                      }
                    >
                      Home
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      exact={true}
                      to="/therapies"
                      className={
                        "profile_header" +
                        (isUrl === "/therapies" ? " selected" : "")
                      }
                    >
                      Therapies
                    </Link>
                  </li>

                </ul>
              </nav>
            </>

        </div>
      </div>
    </>
  )
}

export default ProfileHeader
