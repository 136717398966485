import React from "react";
import Formatter from "../../helpers/Formatter";
import * as Bs from "react-icons/bs";
import { Row, Col, Card } from "react-bootstrap";
import * as Fa from "react-icons/fa";

export default function SessionTile(props) {
  console.log("status_", props.scheduleStatus);

  return (
    <>
      <Card className="pro profile_card p-4 m-2">
        {props.showView == true && (
          <div class="livenow">
            <div class="live-dot"></div>
            <p>Live</p>
          </div>
        )}
        {/* <div class="line_1"></div> */}
        <Row>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="therapy_img_">
              <img
                src={
                  props.image
                    ? props.image
                    : require("../../assets/nest_placeholder.jpg")
                }
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 therapy_info">
            <div class="line_1"></div>
            <h5 className="text-center">{props.therapyName}</h5>
            <p class="date pt-1">
              <Bs.BsCalendarEvent /> {props.date}
            </p>
            <p class="time">
              <Bs.BsClock /> {Formatter.formatTime(props.startTime)} -{" "}
              {Formatter.formatTime(props.endTime)}
            </p>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 therapy_status">
            <div class="line_1"></div>
            <p className="therapist_ mt-4">{props.therapistName}</p>
            <p class="desig">{props.desigination}</p>
            {props.showView == true && props.scheduleStatus != "pending" ? (
              <button className="join" onClick={props.onClickView}>
                <i className="ico">
                  <Fa.FaPlay />
                </i>
                Join Session
              </button>
            ) : (
              ""
            )}
          </div>
        </Row>
        {props.scheduleStatus == "pending" && (
          <p className="pending_ mx-auto">
            This session must be accepted by the therapist before it can begin.
          </p>
        )}
      </Card>

      {/* card End  */}

      {/* <p className="current_session_title">
        Therapy Name :{" "}
        <span
          style={{ float: "right", marginRight: "30px" }}
          className="text-muted"
        >
          {props.therapyName}
        </span>
      </p>
      <hr />
      <p className="current_session_title">
        Therapist:{" "}
        <span
          style={{ float: "right", marginRight: "30px" }}
          className="text-muted"
        >
          {props.therapistName}
        </span>
      </p>
      <hr />
      <p className="current_session_title">
        Date:{" "}
        <span
          style={{ float: "right", marginRight: "30px" }}
          className="text-muted"
        >
          {props.date}
        </span>
      </p>
      <hr />
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-2">
          <p className="current_session_title">
            From: {Formatter.formatTime(props.startTime)} To:{" "}
            {Formatter.formatTime(props.endTime)}
          </p>
        </div>
      </div>
      <hr />
      {props.showView == true && (
        <p className="current_session_title text-center">
          <button className="view_btn" onClick={props.onClickView}>
            View
          </button>
        </p>
      )} */}
    </>
  );
}
