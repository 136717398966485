const ProgramData = [
  {
    id: 1,
    name: "Addictions",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions"
  },
  {
    id: 2,
    name: "Bullying",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 3,
    name: "Feeling lost with daily life",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/lifeOccurrences.jpg",
    category: "life Occurrences",
  },
  {
    id: 4,
    name: "Stress",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 5,
    name: "Anxiety",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 6,
    name: "Demotivated",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/lifeOccurrences.jpg",
    category: "life Occurrences",
  },
  {
    id: 7,
    name: "Panic attacks",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 8,
    name: "Abuse",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 9,
    name: "Cosmetic surgery",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 10,
    name: "Gambling",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 11,
    name: "Burnout",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 12,
    name: "Suicidal thoughts",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 13,
    name: "Self harm",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 14,
    name: "OCD",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 15,
    name: "Sex",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 16,
    name: "Low self esteem",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 17,
    name: "Anger management",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 18,
    name: "Post traumatic stress",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
    
  },
  {
    id: 19,
    name: "Obesity",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 20,
    name: "Depression",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
    
  },
  {
    id: 21,
    name: "Social drug addictions",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 22,
    name: "Neglect",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 23,
    name: "Life traumas",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 24,
    name: "Self confidence",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 25,
    name: "Prescription medications",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 26,
    name: "Insomnia",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 27,
    name: "Food",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 28,
    name: "Online/Internet",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 29,
    name: "Social media",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 30,
    name: "Non prescription medication",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/addictions.jpg",
    category: "addictions",
  },
  {
    id: 31,
    name: "Insecurities",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/wellbeing.jpg",
    category: "well Being",
  },
  {
    id: 32,
    name: "Family mediation",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/lifeOccurrences.jpg",
    category: "life Occurrences",
  },
  {
    id: 33,
    name: "Loss of confidence",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/lifeOccurrences.jpg",
    category: "life Occurrences",
  },
  {
    id: 34,
    name: "Financial stress",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/lifeOccurrences.jpg",
    category: "life Occurrences",
  },
  {
    id: 35,
    name: "Cancer support",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/lifeOccurrences.jpg",
    category: "life Occurrences",
  },
  {
    id: 36,
    
    name: "Divorce",
    image: "https://createroyale.s3.eu-west-2.amazonaws.com/healthcare/lifeOccurrences.jpg",
    category: "life Occurrences",
  },
];

export default ProgramData;
