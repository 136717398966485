import React from "react";
import { useNavigate } from "react-router";
import Lottie from "lottie-react";
import { Row, Col } from "react-bootstrap";
import PageNotFoundAnimation from "../../assets/lottie/pagenotfound.json";
import "./errorPage.css";

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container red-con">
        <Row className="red-row">
          <Col>
            <Lottie
              className="grn"
              animationData={PageNotFoundAnimation}
              loop={true}
            ></Lottie>
          </Col>
          <Col>
            <div className="mx-auto text-center">
              <h6 className="red-msg">Page Not Found</h6>
              <p>Something Went Wrong</p>
              <button
                role="button"
                className="redirect mx-auto"
                onClick={() => navigate("/")}
              >
                <span class="text">Go to Home</span>
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PageNotFound;
