import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import "./Profile.css";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import { Row, Col, Container, Card } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { AppContext } from "../../context/AppContext";
import SessionTile from "./SessionTile";
import { SocketContext } from "../../context/SocketContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import jwt_decode from "jwt-decode";
import Formatter from "../../helpers/Formatter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as Md from "react-icons/md";
import * as Ai from "react-icons/ai";
import placeholder from "../../assets/placeholder01.jpg";

function Profile() {
  const { logoutUser } = useAuth();
  const { bookedTherapies, userDetails, setBookedTherpies, loading } =
    useContext(AppContext);
  const { socket } = useContext(SocketContext);

  const navigate = useNavigate();
  const { get, check } = useLocalStorage("UserPersistent");
  const [socketEvent, setSocketEvent] = useState(null);

  const onClickLogout = () => {
    logoutUser();
    navigate("/auth");
  };

  // useEffect(() => {
  //   convertBookedTherapiesToSingleArray();
  // }, [bookedTherapies]);

  // const convertBookedTherapiesToSingleArray = () => {
  //   const bookedTherapisesList = [];

  //   if (
  //     !bookedTherapies ||
  //     !Array.isArray(bookedTherapies) ||
  //     bookedTherapies.length == 0
  //   ) {
  //     return;
  //   }

  //   bookedTherapies.forEach((therapy) => {
  //     const element = [...therapy.bookings, therapy.therapistDetails];
  //     bookedTherapisesList.push(element);
  //   });

  //   console.log("bookedTherapisesList", bookedTherapisesList);
  // };

  // useEffect(() => {
  //   if (socket != null) {
  //     listenForStreamEvents();
  //   }
  // }, [socket]);

  // useEffect(() => {
  //   handleStreamEvents();
  // }, [socketEvent]);

  const listenForStreamEvents = () => {
    if (!socket) return;
    socket.on("recieve-message", (message) => {});
    socket.on("streamStarted", (data) => {
      setSocketEvent({ event: "streamStarted", data });
    });
    socket.on("streamStopped", (data) => {
      setSocketEvent({ event: "streamStopped", data });
    });
  };

  const handleStreamEvents = () => {
    if (socketEvent == null || setSocketEvent == undefined) return;

    const { data, id } = socketEvent.data;

    //?? HANDLE SOCKET EVENT
    switch (socketEvent.event) {
      case "streamStarted":
        pushNewTherapyToBookedTherapies(data);
        break;
      case "streamStopped":
        removeTherpyFromBookedTherapies(id);
        break;
      default:
        console.log("Invalid Socket Event !!");
        break;
    }
  };

  const pushNewTherapyToBookedTherapies = (data) => {
    if (!data) return;

    const userId = getUserId();

    if (!userId || !data.bookedUser) return;

    if (data.bookedUser != userId) return;

    const currentBookedTherapies = bookedTherapies;

    if (!Array.isArray(currentBookedTherapies)) return;

    let removeIndex = -1;
    if (currentBookedTherapies.length !== 0) {
      for (let index = 0; index < currentBookedTherapies.length; index++) {
        if (currentBookedTherapies[index]._id === data._id) {
          removeIndex = index;
          break;
        }
      }

      if (removeIndex != -1) {
        currentBookedTherapies.splice(removeIndex, 1);
      }

      currentBookedTherapies.push(data);
      setBookedTherpies((prev) => [...prev, currentBookedTherapies]);

      return;
    }

    currentBookedTherapies.push(data);
    setBookedTherpies((prev) => [...prev, currentBookedTherapies]);
  };

  const removeTherpyFromBookedTherapies = (id) => {
    if (!id) return;

    const currentBookedTherapies = bookedTherapies;
    let removeIndex = -1;

    if (currentBookedTherapies.length !== 0) {
      for (let index = 0; index < currentBookedTherapies.length; index++) {
        if (currentBookedTherapies[index]._id === id) {
          removeIndex = index;
          break;
        }
      }

      if (removeIndex != -1) {
        currentBookedTherapies.splice(removeIndex, 1);
        setBookedTherpies((prev) => [...prev, currentBookedTherapies]);
      }
    }
  };
  const getUserId = () => {
    if (!check()) return;
    const localStorageValue = JSON.parse(get());
    const { accessToken } = localStorageValue;
    if (!accessToken) return;
    const { Id } = jwt_decode(accessToken);
    return Id;
  };

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(userDetails.phonenumber);
  return (
    <>
      <header>
        <ProfileHeader />
      </header>
      <main>
        <div className="main">
          <div className="profile_div">
            <div className="pro_div">
              <Container>
                <Row>
                  <Col className="first_Card">
                    <h5 className="ms-2 pt-4">Profile</h5>
                    <Card className="pro_cards profile__">
                      <Card.Img
                        variant="top"
                        src={
                          userDetails.photo != null
                            ? process.env.REACT_APP_AWS_IMAGE_URL +
                              userDetails.photo
                            : require("../../assets/placeholder01.jpg")
                        }
                        alt=""
                        className="profileImg"
                      />
                      <Card.Title className="text-center">
                        {userDetails.name != null
                          ? userDetails.name
                          : "Unknown"}
                      </Card.Title>
                      <div className="text-center">
                        <p style={{ marginBottom: "0px" }}>
                          {userDetails.email != null
                            ? userDetails.email
                            : "Unknown"}
                          <span>
                            <br />
                            {/* {userDetails.phonenumber == null  &&
                              "userDetails.phonenumber"} */}
                          </span>
                        </p>
                      </div>
                      <div class="d-flex justify-content-center mb-3">
                        <button
                          className="edit-pro pro-btn hegh"
                          onClick={() => navigate("/edit-profile")}
                        >
                          <i className="con">
                            <Ai.AiOutlineEdit />
                          </i>
                          Edit Profile
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-dark"
                          onClick={() => onClickLogout()}
                        >
                          <i className="loh">
                            <Md.MdLogout />
                          </i>
                        </button>
                      </div>
                    </Card>
                  </Col>

                  <Col xl={8}>
                    <div class="container mt-3">
                      <h2 className="heads">Present/Upcoming Sessions</h2>

                      <Slider {...slickSettings}>
                        {!loading &&
                          bookedTherapies.length != null &&
                          bookedTherapies.map((therapy) => {
                            if (therapy.streamStatus != "STOPPED") {
                              const therapyDetails = therapy.theraphyDetails;
                              const therapistDetails = therapy.therapistDetails;
                              const schedule = therapy.scheduleDetails;
                              // console.log("therapyDetails", therapyDetails);

                              return (
                                <SessionTile
                                  therapyName={
                                    therapyDetails != null
                                      ? therapyDetails.therapyName
                                      : "N/A"
                                  }
                                  therapistName={
                                    therapistDetails != null
                                      ? therapistDetails.name
                                      : "N/A"
                                  }
                                  date={
                                    therapy != null
                                      ? Formatter.formatDate(therapy.date)
                                      : "N/A"
                                  }
                                  startTime={
                                    schedule != null && schedule.startTime
                                  }
                                  endTime={schedule != null && schedule.endTime}
                                  image={
                                    therapyDetails != null
                                      ? process.env.REACT_APP_AWS_IMAGE_URL +
                                        therapyDetails.photo
                                      : placeholder
                                  }
                                  desigination={
                                    therapistDetails != null &&
                                    therapistDetails.desigination
                                  }
                                  showView={therapy.streamStatus == "STARTED"}
                                  onClickView={function () {
                                    return navigate(
                                      `/classroom/${therapy._id}`
                                    );
                                  }}
                                  scheduleStatus={
                                    therapy.scheduleStatus != null
                                      ? therapy.scheduleStatus
                                      : "None"
                                  }
                                />
                              );
                            }
                          })}
                        {(!bookedTherapies || bookedTherapies.length == 0) && (
                          <div className="card slider mx-auto text-center">
                            <p className="paraph">No Upcoming Sessions</p>
                          </div>
                        )}
                      </Slider>
                    </div>

                    <Col>
                      <div class="container">
                        <h2 className="heads">Finished Sessions</h2>
                        <Card className="ex_cards card-ht">
                          <div className="container">
                            <table class="table table-striped profile-table text-center">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Therapy Name</th>
                                  <th>Therapist Name</th>
                                  <th>Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {bookedTherapies != null &&
                                  Array.isArray(bookedTherapies) &&
                                  bookedTherapies.length != 0 &&
                                  bookedTherapies.map(
                                    (bookedTherapy, index) => {
                                      if (
                                        bookedTherapy.streamStatus ==
                                          "STOPPED" ||
                                        bookedTherapy.streamStatus == "FINISHED"
                                      ) {
                                        const schedule =
                                          bookedTherapy.scheduleDetails;
                                        const therapyDetails =
                                          bookedTherapy.theraphyDetails;
                                        const therapistDetails =
                                          bookedTherapy.therapistDetails;

                                        return (
                                          <tr key={index}>
                                            <td>
                                              {Formatter.formatDate(
                                                bookedTherapy.date
                                              )}
                                            </td>
                                            <td>
                                              {therapyDetails != null
                                                ? therapyDetails.therapyName
                                                : "N/A"}
                                            </td>
                                            <td>
                                              {therapistDetails != null
                                                ? therapistDetails.name
                                                : "N/A"}
                                            </td>

                                            <td>
                                              {schedule.startTime}-
                                              {schedule.endTime}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    }
                                  )}
                                {!bookedTherapies ||
                                  (bookedTherapies.length === 0 && (
                                    <>
                                      <td colSpan="4">
                                        <p className="let">
                                          No sessions to show
                                        </p>
                                      </td>
                                    </>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Profile;
