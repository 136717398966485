import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";

const ProtectedRoute = ({ children }) => {
  const { check } = useLocalStorage("UserPersistent");
  let isAuthenticated = check();
  return isAuthenticated ? children : <Navigate to="/auth" />;
};

const ProtectedAuthRoute = ({ children }) => {
  const { check } = useLocalStorage("UserPersistent");
  let isAuthenticated = check();
  return !isAuthenticated ? children : <Navigate to="/" />;
};

export { ProtectedRoute, ProtectedAuthRoute };
