import React from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header2/Header2";
import BottomBar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";

import * as Bs from "react-icons/bs";
import "./Events.css";

function Events() {
  return (
    <>
      <Transitions>
        {/* header starts */}
        <header>
          <Header2 />
          <section class="pro_banner">
            <div class="proBanner">
              <div class="container">
                <div class="pb_cont">
                  <h1 class="pro_title">Events</h1>
                  <p class="pro_sub">
                    All our Events are self-paced and have been designed by
                    subject matter experts, to give you an interactive and
                    enriched learning experience.
                  </p>
                </div>
                <div class="proBanner_sub_cont">
                  <h2>
                    Home <Bs.BsChevronDoubleRight /> Events
                  </h2>
                </div>
                <img
                  src={require("../../assets/bg-white.png")}
                  alt=""
                  class="banner_shape1"
                />
              </div>
            </div>
          </section>
        </header>
        {/* header ends */}
        {/* main starts  */}
        <main>
          <section className="event_sec">
            <div className="container-sm eve_container">
              <div class="eve">
                <div class="row">
                  <div
                    class="col-lg-6 col-md-6 col-sm-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="40"
                    data-aos-duration="1000"
                  >
                    <div class="event_img">
                      <img
                        src={require("../../assets/events/event5.jpg")}
                        alt=""
                      />
                      <div class="d_card">
                        <p id="fst">06</p>
                        <span id="sec">Dec</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1500"
                  >
                    <div class="event_cont">
                      <p id="date">Sunday | December 06 | 9.00am</p>
                      <h2>September Undergraduate Open House</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Doloremque sunt placeat nemo non vitae provident.
                        Impedit vel nisi expedita. Ullam, fugiat nam assumenda
                        ex omnis aliquam sequi accusantium quaerat quasi.
                      </p>
                      <h4>Charlotte H. Bronk</h4>
                      <p>Founder and Сhairman at Global Innovation Catalyst</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Events card End */}
              <div class="eve">
                <div class="row">
                  <div
                    class="col-lg-6 col-md-6 col-sm-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="40"
                    data-aos-duration="1000"
                  >
                    <div class="event_cont">
                      <p id="date">Sunday | December 09 | 11.00am</p>
                      <h2>September Undergraduate Open House</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Doloremque sunt placeat nemo non vitae provident.
                        Impedit vel nisi expedita. Ullam, fugiat nam assumenda
                        ex omnis aliquam sequi accusantium quaerat quasi.
                      </p>
                      <h4>Charlotte H. Bronk</h4>
                      <p>Founder and Сhairman at Global Innovation Catalyst</p>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1500"
                  >
                    <div class="event_img">
                      <img
                        src={require("../../assets/events/event2.jpg")}
                        alt=""
                      />
                      <div class="d_card">
                        <p id="fst">09</p>
                        <span id="sec">Dec</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Events card End */}
              <div class="eve">
                <div class="row">
                  <div
                    class="col-lg-6 col-md-6 col-sm-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="40"
                    data-aos-duration="1000"
                  >
                    <div class="event_img">
                      <img
                        src={require("../../assets/events/event1.jpg")}
                        alt=""
                      />
                      <div class="d_card">
                        <p id="fst">12</p>
                        <span id="sec">Dec</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1500"
                  >
                    <div class="event_cont">
                      <p id="date">Sunday | December 12 | 3.00pm</p>
                      <h2>Creating Concrete Universes Gloria Anzaldúa</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Doloremque sunt placeat nemo non vitae provident.
                        Impedit vel nisi expedita. Ullam, fugiat nam assumenda
                        ex omnis aliquam sequi accusantium quaerat quasi.
                      </p>
                      <h4>Charlotte H. Bronk</h4>
                      <p>Founder and Сhairman at Global Innovation Catalyst</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Events card End */}
              <div class="eve">
                <div class="row">
                  <div
                    class="col-lg-6 col-md-6 col-sm-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="40"
                    data-aos-duration="1000"
                  >
                    <div class="event_cont">
                      <p id="date">Sunday | January 25 | 11.00am</p>
                      <h2>"Inspiration Memento" Live Commission Art</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Doloremque sunt placeat nemo non vitae provident.
                        Impedit vel nisi expedita. Ullam, fugiat nam assumenda
                        ex omnis aliquam sequi accusantium quaerat quasi.
                      </p>
                      <h4>Charlotte H. Bronk</h4>
                      <p>Founder and Сhairman at Global Innovation Catalyst</p>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-6"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1500"
                  >
                    <div class="event_img">
                      <img
                        src={require("../../assets/events/event4.jpg")}
                        alt=""
                      />
                      <div class="d_card">
                        <p id="fst">25</p>
                        <span id="sec">Jan</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Events card End */}
            </div>
            <div class="ev_bg1"></div>
            <div class="ev_bg2"></div>
            <div class="ev_bg3"></div>
          </section>
          <br />
          <BottomBar />
        </main>
        {/* main ends */}
        {/* footer starts */}
        <footer>
          <Footer />
        </footer>
        {/* footer ends */}
      </Transitions>
    </>
  );
}

export default Events;
