import React, { useEffect, useRef } from "react";
import "./Popup.css";
import * as Ai from "react-icons/ai";

function Popup(props) {
  const popRef = useRef();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        props.setTrigger(false);
      }
    };
    const close2 = (e) => {
      // console.log(popRef.current);
      if (popRef.current && popRef.current.contains(e.target)) {
        props.setTrigger(false);
      }
    };
    window.addEventListener("keydown", close);
    window.addEventListener("mousedown", close2);
    return () => {
      window.removeEventListener("keydown", close);
      window.removeEventListener("mousedown", close2);
    };
  }, []);

  return props.trigger ? (
    <>
      <div className="popup" ref={popRef}>
        <div className="pop-inner">
          <button class="close-btn" onClick={() => props.setTrigger(false)}>
            <Ai.AiFillCloseCircle />
          </button>
          <iframe
            width="800"
            height="600"
            src="https://www.youtube.com/embed/AFwMLBBjk7Y?autoplay=1"
            title="Yoga for Toned Body | Ali Kamenova Yoga"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </>
  ) : (
    ""
  );
}

export default Popup;
