import React, { useState } from "react";
import BaseClient from "../helpers/base_client";
import { APIEndpoints } from "../constants/ApiEndPoints";
import useLocalStorage from "./useLocalStorage";

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { remove, check } = useLocalStorage("UserPersistent");

  const registerUser = async (data, { onSuccess, onFailed }) => {
    setIsLoading(true);
    await BaseClient.post(APIEndpoints.register, data, { onSuccess, onFailed });
  };

  const loginUser = (data, { onSuccess, onFailed }) => {
    setIsLoading(true);
    BaseClient.post(APIEndpoints.login, data, { onSuccess, onFailed });
  };

  const logoutUser = () => check() && remove();

  const verifyUserOTP = async (data, { onSuccess, onFailed }) => {
    try {
      setIsLoading(true);
      await BaseClient.post(APIEndpoints.verifyOTP, data, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    loginUser: loginUser,
    registerUser: registerUser,
    logoutUser: logoutUser,
    verifyUserOTP,
  };
};

export default useAuth;
