import React, { useContext, useState, useEffect } from "react";
import "./StreamPage.css";
import { Tooltip } from "@mui/material";
import Player from "../../components/Player/Player";
import * as Ai from "react-icons/ai";
import * as Fi from "react-icons/fi";
import * as Ri from "react-icons/ri";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../context/AppContext";
import { useParams } from "react-router";
import StreamChat from "./StreamChat";
import WebRTCBroadcast from "./WebRTCBroadcast";
import LiveSession from "../../components/LiveSession/LiveSession";
import ChatSession from "../../components/LiveSession/ChatSession";
import AlertModal from "../../components/AlertModal/AlertModal";

function StreamPage() {
  const { userDetails, setBookedTherpies, bookedTherapies } =
    useContext(AppContext);

  const [showModal, setShowModal] = useState(false);

  const params = useParams();

  const [chatAvailable, setChatAvailable] = useState(false);

  const [currentTherpy, setCurrentTherpy] = useState(null);

  const { user } = useContext(AppContext);

  const chatClick = () => {
    setChatAvailable(!chatAvailable);
  };

  useEffect(() => {
    initFunction();
  }, [bookedTherapies]);

  useEffect(() => {
    console.log("currentTherpy", currentTherpy);
  }, [currentTherpy]);

  const initFunction = () => {
    const id = params.id;
    if (
      !bookedTherapies ||
      !Array.isArray(bookedTherapies) ||
      bookedTherapies.length == 0
    ) {
      return;
    }

    // for (let index = 0; index < bookedTherapies.length; index++) {
    //   const bookedTherapy = bookedTherapies[index];
    //   for (let pos = 0; pos < bookedTherapy.bookings.length; pos++) {
    //     const therapy = bookedTherapy.bookings[pos];
    //     if (therapy._id == params.id) {
    //       setCurrentTherpy(therapy);
    //     }
    //   }
    // }

    const therapyData = bookedTherapies.find((therapy) => therapy._id == id);
    setCurrentTherpy(therapyData);
  };

  return (
    <>
      <AlertModal
        title={"User Joined"}
        type={"info"}
        openAlertModal={showModal}
        onCloseAlertModal={() => setShowModal(false)}
      />
      <section className="stream_page">
        <LiveSession />
      </section>
    </>
  );
}

export default StreamPage;
