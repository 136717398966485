import React, { useState } from "react";
import BaseClient from "../helpers/base_client";
import { APIEndpoints } from "../constants/ApiEndPoints";
export default function useCategories() {
  const [allCategories, setAllCategories] = useState([]);

  const getListOfCategories = async () => {
    await BaseClient.get(APIEndpoints.getAllCategories, {
      onSuccess: (res) => {
        if (!res.data) return;
        setAllCategories(res.data);
      },
      onFailed: (error) => {
        console.log("Error", error);
      },
    });
  };

  return { getListOfCategories, allCategories };
}
