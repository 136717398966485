import React from "react";
import "./BottomBar.css"

// icon packs
import * as Fa from "react-icons/fa";
import * as Bi from "react-icons/bi";
import * as Im from "react-icons/im";

function BottomBar() {
  return (
    <>
      <div class="btm_details">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="details_box d-flex">
                <div class="ico_c">
                  <i class="ico_c2">
                    <Im.ImBook />
                  </i>
                </div>
                <span>80,000 ONLINE Programmes</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="details_box d-flex">
                <div class="ico_c">
                  <i class="ico_c2">
                    <Fa.FaPeopleArrows />
                  </i>
                </div>
                <span>EXPERT INSTRUCTION</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="details_box d-flex">
                <div class="ico_c">
                  <i class="ico_c2">
                    <Bi.BiKey />
                  </i>
                </div>
                <span>LIFETIME ACCESS</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BottomBar;
