const useLocalStorage = (key) => {
  const store = (value) => localStorage.setItem(key, value);
  const get = () => localStorage.getItem(key);
  const decodeAndGet = () => check() && JSON.parse(get());
  const check = () => localStorage.getItem(key) != null;
  const remove = () => localStorage.removeItem(key);

  return {
    store: store,
    get: get,
    decodeAndGet: decodeAndGet,
    check: check,
    remove: remove,
  };
};

export default useLocalStorage;
