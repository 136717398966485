import React, { useState } from "react";
import BaseClient from "../helpers/base_client";
import { APIEndpoints } from "../constants/ApiEndPoints";
export default function useUser() {
  const [userDetails, setUserDetails] = useState({});
  const [userDetailsLoading, setUserDetailsLoading] = useState(null);

  //Get user details using token
  const getUserDetails = async () => {
    try {
      setUserDetailsLoading(true);
      await BaseClient.get(APIEndpoints.getuserdetails, {
        onSuccess: (res) => {
          setUserDetails(res.data);
          window.sessionStorage.setItem(
            "UserDetails",
            JSON.stringify(res.data)
          );
        },
        onFailed: (error) => {
          console.error("Error", error);
          window.sessionStorage.removeItem("UserDetails");
        },
      });
    } finally {
      setUserDetailsLoading(false);
    }
  };

  return { getUserDetails, userDetails, userDetailsLoading };
}
