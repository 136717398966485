import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./About.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { AnimatePresence, motion } from "framer-motion";
import * as Md from "react-icons/md";
import Bottombar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";
import Popup from "../../components/Popup/Popup";

function About() {
  const [toggleState, setToggleState] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [videoUp, setVideoUp] = useState(false);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const accordionChange = (isExpanded, panel) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Transitions>
        <Popup trigger={videoUp} setTrigger={setVideoUp} />
        <header>
          <Header />
          {/* <section class="pro_banner">
            <div class="proBanner">
              <div class="container">
                <div class="pb_cont">
                  <h1 class="pro_title">About us</h1>
                  <p class="pro_sub">All About Ourself</p>
                </div>
                <div class="proBanner_sub_cont">
                  <h2>
                    Home <Bs.BsChevronDoubleRight /> About Us
                  </h2>
                </div>
                <img
                  src={require("../../assets/bg-white.png")}
                  alt=""
                  class="banner_shape1"
                />
              </div>
            </div>
          </section> */}
        </header>
        <main>
          <section class="needToKnow">
            <div class="bgg"></div>
            <div class="container-sm">
              <h1
                className="page_head_title"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                About.
              </h1>
              <div class="row">
                <div
                  class="col-lg-6 col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="know_img">
                    <img
                      src={require("../../assets/about/about4.jpg")}
                      alt=""
                    />
                  </div>
                </div>
                {/* col End */}
                <div
                  class="col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <div class="know_cont">
                    <p class="sub_head">NEST HEALTHCARE</p>
                    <h1>"We are here to help you"</h1>
                    <p className="know_para">
                      Whatever mental health condition you may be suffering from
                      we are here to help, we understand the complexity,
                      delicateness and devastating effects mental health can
                      have on a person life.
                    </p>
                    <p>
                      At Nest healthcare we have created a unique approach and a
                      special place, where people can escape and retreat from
                      the daily challenges of life and begin their new journey
                      to a more positive mental health wellbeing. ​
                    </p>
                    <h4 className="sub_head2">
                      Want to find out more about how we can help you?{" "}
                    </h4>
                    <p>
                      Get in touch with us today for more details about the
                      treatments and therapies that we can offer you.
                    </p>
                    <a class="know_btn" href="mailto:Jennifer@nesthealthcare.co.uk">CONTACT US</a>
                  </div>
                </div>
                {/* col End */}
              </div>
            </div>
          </section>
          <section class="sec_head">
            <div class="container">
              <h1
                className="sec_head_h1"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                Nest Healthcare provides complete anonymity to all it clients.{" "}
                <br />
                If required, exclusive access to the premises, its facilities,
                specialist therapists and support staff is available . <br />
                Our clinic can also accommodate close family members/associates
                to support you with complete privacy if desired.
              </h1>
            </div>
          </section>
          <section class="who_we_are">
            <div class="container">
              <div class="bloc-tabs" data-aos="zoom-out">
                <motion.div
                  class={toggleState === 1 ? "tabs active-tab" : "tabs"}
                  onClick={() => toggleTab(1)}
                >
                  Why Us
                </motion.div>
                <motion.div
                  class={toggleState === 2 ? "tabs active-tab" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  NHS Referrals
                </motion.div>
                <motion.div
                  class={toggleState === 3 ? "tabs active-tab" : "tabs"}
                  onClick={() => toggleTab(3)}
                >
                  Our story
                </motion.div>
              </div>
              <div
                class="content-tabs"
                data-aos="zoom-out"
                data-aos-duration="1000"
              >
                <div
                  class={
                    toggleState === 1 ? "content  active-content" : "content"
                  }
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tabss_cont">
                          <h2>NEST Healthcare</h2>

                          <ReactReadMoreReadLess
                            charLimit={700}
                            readMoreText={"Read more ..."}
                            readLessText={"Read less ▲"}
                          >
                            Nest Healthcare, we have created a space where
                            people can escape and retreat from the daily
                            challenges of life. We have developed a
                            non-clinical, peaceful and tranquil environment
                            allowing each person the space and time to
                            rehabilitate. Being involved in health care services
                            for over 16 years has given our organisation a wide
                            range of experience of how we feel care should be
                            deliveredAt Nest Healthcare, we have created a space
                            where people can escape and retreat from the daily
                            challenges of life. We have developed a
                            non-clinical, peaceful and tranquil environment
                            allowing each person the space and time to
                            rehabilitate. Being involved in health care services
                            for over 16 years has given our organisation a wide
                            range of experience of how we feel care should be
                            delivered. At Nest Healthcare, we have created a
                            space where people can escape and retreat from the
                            daily challenges of life. We have developed a
                            non-clinical, peaceful and tranquil environment
                            allowing each person the space and time to
                            rehabilitate. Being involved in health care services
                            for over 16 years has given our organisation a wide
                            range of experience of how we feel care should be
                            delivered. ​​ We believe our small scale care model
                            gives us the fundamental ability and advantage to
                            work closer with each person to give them the
                            flexibility to build and adapt a tailored, therapy
                            and treatment programme that is most responsive to
                            their lives, so that they can begin to work on
                            making a positive change for themselves, their
                            family and friends
                          </ReactReadMoreReadLess>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tab_img" style={{ margin: "10px" }}>
                          <img
                            src={require("../../assets/about/about1.jpg")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-tabs">
                <div
                  class={
                    toggleState === 2 ? "content  active-content" : "content"
                  }
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tab_img">
                          <h2>How we can help</h2>
                          <p style={{ height: "auto" }}>
                            Nest Healthcare Ltd. runs a registered, 5-bed
                            residential rehabilitation clinic, providing
                            complimentary and cost-effective services to the
                            NHS, dealing with a range of addiction, mental
                            health and wellbeing-related conditions. Our
                            fully-equipped clinic and spacious gardens, all in a
                            relaxing coastal setting, engender an atmosphere of
                            tranquillity and wellbeing that create the perfect
                            environment to aid patients’ recovery.
                          </p>

                          <img
                            src={require("../../assets/about/about2.jpg")}
                            alt=""
                            style={{ float: "right" }}
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tabss_cont">
                          <h5>Our four key services</h5>
                          <ol>
                            <li>
                              <b>Early intervention</b> Our professional team
                              focus on building trust and rapport with each
                              individual from admission through to community
                              reintegration.
                            </li>
                            <li>
                              <b>Adaptive Therapy</b>
                              Targeted, engaging and personalised therapy
                              programmes are designed and delivered by our
                              multi-disciplinary team.
                            </li>
                            <li>
                              <b>Responsive Aftercare</b>
                              We create personalised, effective aftercare plans
                              to prevent long-term readmissions into the health
                              and social care system.
                            </li>
                            <li>
                              <b>Stepdown Service</b> We can also provide
                              post-clinic continuity of care and support when
                              patients resume living independently in the
                              community. Successful rehabilitation Prior to
                              patient discharge, our professionals will work
                              closely with the NHS Team to develop effective
                              aftercare plans, ensuring that patients are
                              adequately supported to achieve long-term recovery
                              and relapse prevention.
                            </li>
                          </ol>
                          <h4>Successful rehabilitation</h4>
                          <p>
                            Prior to patient discharge, our professionals will
                            work closely with the NHS Team to develop effective
                            aftercare plans, ensuring that patients are
                            adequately supported to achieve long-term recovery
                            and relapse prevention.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-tabs">
                <div
                  class={
                    toggleState === 3 ? "content  active-content" : "content"
                  }
                >
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tabss_cont">
                          <h2>On a mission to improve mental health</h2>
                          <p>
                            Nest Healthcare was founded in 2022 by Robin Damhar
                            and offers a space where people can escape and
                            retreat from the daily challenges of life. “It’s as
                            far from a clinical environment as we can make it.
                            We want it to be a relaxing, peaceful and tranquil
                            place where people can start their journey to
                            rehabilitation” Robin explains. With a background in
                            health care services stretching back 16 years the
                            Nest Healthcare model has been developed to reflect
                            how the team believe care should be delivered. “Our
                            small scale care model gives us the fundamental
                            ability to work closely with each person. This means
                            we can create a flexible and tailored programme for
                            each individual, taking into account their
                            lifestyles. The end goal is to help to make a
                            positive change in their lives – for themselves and
                            for their family and friends.” Nest’s team of
                            experts offer many different therapies, both for
                            outpatients and for inpatient care. If you think
                            this could be the way forward for your own mental
                            health please contact us on 01255 448888 or complete
                            our enquiry form.
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tab_img">
                          <img
                            src={require("../../assets/about/about4.jpg")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="discover">
            <div class="container-fluid">
              <div class="discover_area">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-sm-4 drip-img">
                      <div class="discover_img" data-aos="zoom-out-right">
                        <img
                          src={require("../../assets/about/about1.jpg")}
                          className="ano-img"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="discover_cont">
                        <h1 data-aos="zoom-out-left">Discover your Life</h1>
                        <div class="d-flex">
                          <div
                            className="ripple"
                            onClick={() => setVideoUp(true)}
                          >
                            <img
                              src={require("../../assets/play-button.png")}
                              alt=""
                            />
                          </div>
                          <p id="term__" data-aos="zoom-out-left">
                            We offer professional SEO services that help
                            websites increase their organic search score
                            drastically in order to compete for the highest
                            rankings.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="faq">
            <div class="container">
              <div class="faq_cont">
                <p>QUESTION & ANSWERS (Q&A)</p>
                <h1>Frequently Asked Questions (FAQ)</h1>

                <div
                  class="accord_area"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel1")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel1_header panel__"
                      aria-label="panel1_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        We have a team of experienced teachers
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}

                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel2")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel2_header panel__"
                      aria-label="panel2_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        We offer academic excellence combined with innovative
                        thinking
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}

                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel3")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel3_header panel__"
                      aria-label="panel3_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        We challenge you to discover your potential and support
                        you in fulfilling
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}

                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel4")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel4_header panel__"
                      aria-label="panel4_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        Careers Service - finding work during and after studying
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}

                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={(event, isExpanded) =>
                      accordionChange(isExpanded, "panel5")
                    }
                    className="accord"
                  >
                    <AccordionSummary
                      className="panel5_header panel__"
                      aria-label="panel5_header"
                      expandIcon={<Md.MdOutlineUnfoldMore />}
                    >
                      <h1 className="accord_title">
                        We challenge you to discover your potential and support
                        you in fulfilling
                      </h1>
                    </AccordionSummary>
                    <AccordionDetails className="accord_details">
                      <p className="accord_p">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Aliquid blanditiis harum a, corrupti veritatis
                        molestias eligendi mollitia quasi labore sed, velit
                        saepe fugit aperiam officiis quos sequi voluptates.
                        Eveniet, numquam?
                      </p>
                      <h2 className="accord_sub_h">
                        A transformational experience that will shape your
                        unique profile and help you launch your professional
                        career.
                      </h2>
                      <p className="accord_p">
                        With the dawn of the twentieth century, the Company
                        handed over the Institute to the University of London.
                        It was re-christened Goldsmiths College and the modern
                        era of Goldsmiths had begun.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  {/* accordion end */}
                </div>
              </div>
            </div>
          </section>
          <section class="trial2">
            <div class="trial2_cont">
              <img
                className="abt_bgg"
                src={require("../../assets/about/background_img.jpg")}
              />
              <div class="container">
                <div class="trial2_container">
                  <h1>A positive place for your healing journey</h1>
                  <p>
                    Whatever mental health condition you may be suffering from
                    you can rely on our help to understand the complexity,
                    fragility and devastating effects mental health can have on
                    your life. By choosing Nest Healthcare you will have access
                    to a unique approach and a special place, where you can
                    escape and retreat from the daily challenges of life and
                    begin your new journey to a more positive mental state of
                    wellbeing.
                  </p>
                  {/* <button class="trial_btn">
                    Start my free month <Bs.BsChevronRight />
                  </button> */}
                </div>
              </div>
            </div>
            <Bottombar />
          </section>
        </main>
        {/* Main End */}
        <footer>
          <Footer />
        </footer>
        {/* Footer End */}
      </Transitions>
    </>
  );
}

export default About;
