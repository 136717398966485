import React, { createContext, useState } from "react";
import { useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import usePayment from "../hooks/usePayment";
import useTheraphy from "../hooks/useTheraphy";
import useUser from "../hooks/useUser";
import useCategories from "../hooks/useCategories";
import useUserProfile from "../hooks/useUserProfile";
import usePassword from "../hooks/usePassword";

export const AppContext = createContext();
export const AppContextProvider = (props) => {
  const [user, setUser] = useState({});
  const [isSignup, setIsSignUp] = useState("login");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { check } = useLocalStorage("UserPersistent");
  const { getUserDetails, userDetails, userDetailsLoading } = useUser();
  const [selectedScheduleSlot, setSelectedScheduleSlot] = useState(null);
  const [selectedBookingDate, setSelectedBookingDate] = useState(new Date());
  const [activeStep, setActiveStep] = useState(0);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [userData, setuserData] = useState([]);

  const {
    getTherphiesCategory,
    getScheduledTheraphis,
    getBookedScheduleOfUser,
    bookedTherapies,
    setBookedTherpies,
    findScheduleByDate,
    selectedDateSchedules,
    categories,
    therapies,
    setTherapies,
    therapyLoading,
    disableButton,
    setDisableButton,
    programsCollections,
    scheduledTherapiesLoading,
    getUserListOfSchedule,
    bookedUsersList,
  } = useTheraphy();

  const {
    createPaymentIntent,
    stripePromise,
    onPaymentElementReady,
    isPaymentElementLoaded,
    options,
    loading,
    stripePaymentClientSecret,
    setStripeClientSecret,
    confirmPayment,
    setIsPaymentElementLoaded,
    billingAddress,
    setBillingAddress,
    paymentStatus,
    iniateNMIPayment,
  } = usePayment();

  const { updateUser } = useUserProfile();
  const { getListOfCategories, allCategories } = useCategories();
  const { forgotPassword, passwordloading, otpVerification, resetPassword } =
    usePassword();
  useEffect(() => {
    setIsAuthenticated(check());
    getTherphiesCategory();
    getListOfCategories();
    getScheduledTheraphis();

    if (check()) {
      getBookedScheduleOfUser();
      getUserDetails();
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isSignup,
        setIsSignUp,
        isAuthenticated,
        categories,
        therapies,
        setTherapies,
        setIsAuthenticated,
        createPaymentIntent,
        stripePromise,
        onPaymentElementReady,
        isPaymentElementLoaded,
        options,
        loading,
        stripePaymentClientSecret,
        setStripeClientSecret,
        confirmPayment,
        bookedTherapies,
        setBookedTherpies,
        userDetails,
        getBookedScheduleOfUser,
        findScheduleByDate,
        selectedDateSchedules,
        selectedScheduleSlot,
        setSelectedScheduleSlot,
        billingAddress,
        setBillingAddress,
        selectedBookingDate,
        setSelectedBookingDate,
        activeStep,
        setActiveStep,
        paymentStatus,
        therapyLoading,
        disableButton,
        setDisableButton,
        getListOfCategories,
        allCategories,
        programsCollections,
        remoteUsers,
        setRemoteUsers,
        userDetailsLoading,
        getUserDetails,
        scheduledTherapiesLoading,
        updateUser,
        forgotPassword,
        passwordloading,
        otpVerification,
        resetPassword,
        players,
        setPlayers,
        getUserListOfSchedule,
        bookedUsersList,
        userData,
        setuserData,
        iniateNMIPayment,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
