import React, { useContext } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { AppContext } from "../../context/AppContext";
import "./Checkout.css";

export default function StripePaymentElement(props) {
  const stripe = useStripe();
  const elements = useElements();

  const {
    onPaymentElementReady,
    stripePaymentClientSecret,
    loading,
    isPaymentElementLoaded,
    confirmPayment,
  } = useContext(AppContext);

  const onClickOnBook = async (e) => {
    e.preventDefault();

    await confirmPayment({
      stripe,
      elements,
      paymentSuccess: props.paymentSuccess,
      paymentFailure: props.paymentFailure,
    });
  };

  return (
    <>
      <PaymentElement id="payment-element" onReady={onPaymentElementReady} />
      <p class="termsconditions">
        By clicking the "book now", You agreeing our{" "}
        <a href="/">terms & Conditions</a>
      </p>
      <button
        class="btn checkout_btn"
        type="submit"
        onClick={onClickOnBook}
        disabled={!stripePaymentClientSecret || !isPaymentElementLoaded}
      >
        Book Now
      </button>
    </>
  );
}
