import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import "./Checkout.css";
import * as Fi from "react-icons/fi";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import Signup from "../../components/Signup/Signup";
import Login from "../../components/Login/Login";
import StripePaymentElement from "./StripePaymentElement";
import { Elements } from "@stripe/react-stripe-js";
import toast, { Toaster } from "react-hot-toast";
import Formatter from "../../helpers/Formatter";
import Validator from "../../utils/validator";
// import Cards from "react-credit-cards-2";
import { BeatLoader } from "react-spinners";

function Checkout() {
  const navigate = useNavigate();
  const params = useParams();

  const [isAuthChange, setIsAuthChange] = useState(false);
  const [selectedTherapy, setSelectedTherapy] = useState(null);
  const { iniateNMIPayment, loading } = useContext(AppContext);
  const [cardDetails, setCardDetails] = useState({
    number: "",
    expiry: "",
    cvv: "",
  });
  const [cardError, setCardError] = useState(false);

  const {
    isAuthenticated,
    setIsSignUp,
    isSignup,
    therapies,
    stripePaymentClientSecret,
    setSelectedScheduleSlot,
    selectedScheduleSlot,
    billingAddress,
    setBillingAddress,
    selectedBookingDate,
  } = useContext(AppContext);

  useEffect(() => {
    initFunction();
    const localData = JSON.parse(sessionStorage.getItem("selectedTherapy"));
    if (localData != null) {
      setSelectedScheduleSlot(localData);
      return;
    }
  }, []);

  const initFunction = () => {
    if (!params.id) return;
    const therapy = therapies.find((therapy) => therapy._id == params.id);
    if (!therapy) return;
    setSelectedTherapy(therapy);
  };

  const navigateToSuccessPage = () => {
    navigate("/success");
  };

  const handleValidation = () => {
    if (
      billingAddress.fullName.length === 0 ||
      billingAddress.email.length === 0 ||
      billingAddress.postcode.length === 0 ||
      billingAddress.state.length === 0
    ) {
      toast.error("Please fill all the requires forms!");
      return false;
    }
    if (Validator.validateEmail(billingAddress.email) === false) {
      toast.error("Email address is invalid");
      return false;
    }
    if (
      !params.id ||
      stripePaymentClientSecret ||
      !selectedBookingDate ||
      Object.keys(billingAddress).length == 0
    ) {
      return false;
    }
    if (
      cardDetails.number.length === 0 ||
      cardDetails.expiry.length === 0 ||
      cardDetails.cvv.length === 0
    ) {
      setCardDetails((prev) => ({
        ...prev,
        error: true,
      }));
      return false;
    }
  };

  const ChangeAuth = () => {
    setIsAuthChange(!isAuthChange);
    setIsSignUp("signup");
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setBillingAddress({ ...billingAddress, [name]: value });
  };

  const handleCardChange = (e) => {
    const regex = /^[0-9\b]+$/;
    const inputValue = e.target.value.replace(/\s/g, "");

    if (
      inputValue === "" ||
      (regex.test(inputValue) && inputValue.length <= 19)
    ) {
      let formattedValue = inputValue;
      if (formattedValue.length > 4) {
        formattedValue = formattedValue.replace(/(\d{4})(?=\d)/g, "$1 ");
      }

      setCardDetails((prev) => ({ ...prev, number: formattedValue }));
    }
  };

  const handleCardDateChange = (event) => {
    const inputText = event.target.value.replace(/[^\d]/g, "");

    if (inputText.length <= 2) {
      setCardDetails((prev) => ({ ...prev, expiry: inputText, error: "" }));
    } else {
      const month = inputText.slice(0, 2);
      const year = inputText.slice(2, 4);

      const currentYearLastTwoDigits = new Date()
        .getFullYear()
        .toString()
        .slice(2);
      if (year < currentYearLastTwoDigits) {
        setCardDetails((prev) => ({
          ...prev,
          expiry: `${month}/${year}`,
          error: true,
        }));
      } else {
        setCardDetails((prev) => ({
          ...prev,
          expiry: `${month}/${year}`,
          error: false,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValidated = handleValidation();

    if (cardDetails.error) {
      toast.error("invalid Date");
      return;
    }

    if (isValidated) return;

    const payload = {
      scheduleDetails: {
        therapyDetails: selectedScheduleSlot.slot.therapyDetails,
        therapistDetails: selectedScheduleSlot.schedule.therapistDetails,
        slotDetails: {
          ...selectedScheduleSlot.slot,
          date: selectedBookingDate,
          therapyDetails: null,
        },
      },
      billingAddress,
      ccnumber: cardDetails.number.split(" ").join(""),
      cvv: cardDetails.cvv,
      ccexpiry: cardDetails.expiry.split("/").join(""),
    };

    // console.log(payload);

    await iniateNMIPayment(payload, {
      onSuccess: (res) => {
        toast.success("Payment Completed!");
        console.log(res);
        setTimeout(() => {
          navigate("/success");
        }, 1000);
      },
      onFailed: (err) => {
        toast.error(err.message);
        console.log(err);
      },
    });
  };

  // console.log("price", selectedScheduleSlot.schedule);

  return (
    <>
      <Toaster position="top-center" />
      <main>
        <section class="checkout_main">
          <div class="container">
            <div class="billing">
              <h1>Checkout.</h1>
              <div class="row flex-column-reverse flex-lg-row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  {isAuthenticated ? (
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div class="form-group checkout_form">
                        <label for="">First & Last name</label>
                        <input
                          type="text"
                          name="fullName"
                          class="form-control checkout_name"
                          placeholder="Your full name"
                          onChange={onChange}
                          value={billingAddress.fullName}
                          required
                        />
                      </div>
                      <div class="form-group checkout_form">
                        <label for="">Email address</label>
                        <input
                          name="email"
                          type="email"
                          class="form-control checkout_mail"
                          placeholder="Your Email addresss"
                          onChange={onChange}
                          value={billingAddress.email}
                          required
                        />
                      </div>
                      <div class="form-group checkout_form">
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label for="">State/County</label>
                              <input
                                name="state"
                                type="text"
                                placeholder="Enter state or county"
                                class="form-control checkout_state"
                                onChange={onChange}
                                value={billingAddress.state}
                                required
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <label for="">Zip/Postcode </label>
                              <input
                                name="postcode"
                                type="text"
                                placeholder="Enter Postcode"
                                class="form-control checkout_po"
                                onChange={onChange}
                                value={billingAddress.postcode}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="payement_method checkout_form">
                        <h5>Payment Method</h5>

                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="">Card Number</label>
                              <input
                                name="ccnumber"
                                type="text"
                                placeholder="**** **** **** ****"
                                class="form-control checkout_state"
                                value={cardDetails.number}
                                onChange={(e) => handleCardChange(e)}
                                required
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mt-2">
                              <label for="">Expiration</label>
                              <input
                                name="ccexpiry"
                                type="text"
                                placeholder="MM/YY"
                                class="form-control checkout_po"
                                value={cardDetails.expiry}
                                onChange={(e) => handleCardDateChange(e)}
                                required
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group mt-2">
                              <label for="">CVV</label>
                              <input
                                name="cvv"
                                type="text"
                                placeholder="CVC"
                                class="form-control checkout_po"
                                onChange={(e) =>
                                  setCardDetails((prev) => ({
                                    ...prev,
                                    cvv: e.target.value,
                                  }))
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <button className="sub-payment">
                          {!loading ? (
                            "Submit"
                          ) : (
                            <BeatLoader
                              color={"white"}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          )}
                        </button>
                      </div>
                    </form>
                  ) : (
                    <>
                      <div class="container">
                        <div class="no_Auth">
                          <h2>Oops! You haven't logged in! Please </h2>
                          <button className="login_" onClick={ChangeAuth}>
                            {!isAuthChange ? "SignUp" : "login"}
                          </button>
                          <h5>(OR)</h5>
                        </div>
                        {!isSignup ? (
                          <>
                            <Signup />
                          </>
                        ) : (
                          <>
                            <Login checkoutPage={true} />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                  {selectedScheduleSlot.slot && (
                    <div class="card billing_card">
                      <h5>Billing Summary</h5>
                      <table>
                        {selectedScheduleSlot.slot.therapyDetails && (
                          <tr>
                            <td id="bill_title">Therapy :</td>
                            <td>
                              {
                                selectedScheduleSlot.slot.therapyDetails
                                  .therapyName
                              }
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td id="bill_title">Date & Time :</td>
                          <td>
                            {Formatter.formatDate(selectedBookingDate)} |{" "}
                            {Formatter.formatTime(
                              selectedScheduleSlot.slot.startTime
                            )}
                            -
                            {Formatter.formatTime(
                              selectedScheduleSlot.slot.endTime
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td id="bill_title">Therapist name:</td>
                          <td>
                            {selectedScheduleSlot.schedule.therapistDetails !=
                              null &&
                              selectedScheduleSlot.schedule.therapistDetails
                                .name}
                          </td>
                        </tr>

                        <tr>
                          <td class="amount">Amount</td>
                          <td class="amount">
                            {selectedScheduleSlot.slot != null
                              ? `£ ${selectedScheduleSlot.schedule.therapistDetails.price}`
                              : ""}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer></footer>
    </>
  );
}

export default Checkout;
