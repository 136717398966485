import React from "react";
import Profile from "../pages/Profile/Profile";
import Home from "../pages/Home/Home";
import Programme from "../pages/Programme/Programme";
import Instructor from "../pages/Instructor/Instructor";
import Events from "../pages/Events/Events";
import About from "../pages/About/About";
import Blog from "../pages/Blog/Blog";
import Auth from "../pages/Auth/Auth";
import StreamPage from "../pages/StreamPage/StreamPage";
import Therapies from "../pages/Theropies/Therapies";
import Checkout from "../pages/Checkout/Checkout";
import SuccessPage from "../pages/resultPage/SuccessPage";
import ErrorPage from "../pages/resultPage/ErrorPage";
import TherapyStepper from "../pages/Theropies/TherapyStepper";
import LiveSession from "../components/LiveSession/LiveSession";
import CircleLoader from "../components/common/CircleLoader";
import LeavePage from "../components/LiveSession/LeavePage";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ConfirmPassword from "../pages/ForgotPassword/ConfirmPassword";
import EditProfile from "../pages/Profile/EditProfile";
import PageNotFound from "../pages/Errors/PageNotFound";
import ConfirmOtp from "../pages/ForgotPassword/ConfirmOtp";
import Payment from "../pages/Checkout/Payment";
const AppRoutes = [
  // { isProtected: false, path: "/", element: <Therapies />, title: "Home Page" },
  { isProtected: false, path: "/", element: <Home /> },
  {
    isProtected: false,
    path: "/program",
    element: <Programme />,
    title: "Program Page",
  },
  {
    isProtected: false,
    path: "/instructor",
    element: <Instructor />,
    title: "Instructor Page",
  },
  {
    isProtected: false,
    path: "/events",
    element: <Events />,
    title: "Events Page",
  },
  {
    isProtected: false,
    path: "/about",
    element: <About />,
    title: "About Page",
  },
  { isProtected: false, path: "/blog", element: <Blog />, title: "Blog Page" },
  {
    isProtected: false,
    isProtectedAuth: true,
    path: "/auth",
    element: <Auth />,
    title: "Auth Page",
  },
  {
    isProtected: true,
    path: "/profile",
    element: <Profile />,
    title: "Profile Page",
  },
  {
    isProtected: false,
    path: "/classroom/:id",
    element: <StreamPage />,
    title: "Classroom Page",
  },
  {
    isProtected: false,
    path: "/therapies",
    element: <Therapies />,
    title: "Therapies Page",
  },
  {
    isProtected: false,
    path: "/booking/:id",
    element: <TherapyStepper />,
    title: "Booking Page",
  },
  {
    isProtected: true,
    path: "/checkout",
    element: <Checkout />,
    title: "Checkout Page",
  },
  {
    isProtected: false,
    path: "/payment/:id",
    element: <Payment />,
    title: "payment Page",
  },
  {
    isProtected: true,
    path: "/success",
    element: <SuccessPage />,
    title: "Success Page",
  },
  {
    isProtected: true,
    path: "/error",
    element: <ErrorPage />,
    title: "Error Page",
  },
  {
    isProtected: true,
    path: "/live",
    element: <StreamPage />,
    title: "Live Page",
  },
  {
    isProtected: false,
    path: "/leavepage",
    element: <LeavePage />,
    title: "Leave Page",
  },
  {
    isProtected: false,
    path: "/forgot-password",
    element: <ForgotPassword />,
    title: "Forgot Password Page",
  },
  {
    isProtected: false,
    path: "/otp",
    element: <ConfirmOtp />,
    title: "Confirm OTP",
  },
  {
    isProtected: false,
    path: "/confirm-password",
    element: <ConfirmPassword />,
    title: "Confirm Password Page",
  },
  {
    isProtected: false,
    path: "/edit-profile",
    element: <EditProfile />,
    title: "Edit Profile Page",
  },
  {
    isProtected: false,
    path: "/pagenotfound",
    element: <PageNotFound />,
    title: "Not Found Page",
  },
];

export default AppRoutes;
