import Validator from "../utils/validator";

const signUpFormValidator = (values) => {
  const errors = {};
  if (!values.fullName) {
    errors.fullName = "This field cannot be blank";
  }
  // if (values.fullName && !Validator.validateName(values.fullName)) {
  //   errors.fullName = "Name must contains letters only";
  // }
  if (!values.username) {
    errors.username = "This field cannot be blank";
  }
  if (!values.password) {
    errors.password = "This field cannot be blank";
  }
  if (values.password.length < 6) {
    errors.password = "Password must be more than 6 characters";
  }
  return errors;
};

export default signUpFormValidator;
