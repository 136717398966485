const loginFormValidator = (values) => {
  
  const errors = {};
  if (!values.username) {
    errors.username = "This field cannot be blank";
  }
  if (!values.password) {
    errors.password = "This field cannot be blank";
  }
  if (values.password.length < 6) {
    errors.password = "Password must be more than 6 characters";
  }
  return errors;
};

export default loginFormValidator;
