import React from "react";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import BottomBar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";

import "./Instructor.css";
// icons
import * as Bs from "react-icons/bs";
import * as Fa from "react-icons/fa";

function Instructor() {
  return (
    <>
      <Transitions>
        {/* header starts */}
        <header>
          <Header />
          {/* <section class="pro_banner">
            <div class="proBanner">
              <div class="container">
                <div class="pb_cont">
                  <h1 class="pro_title">Instructors</h1>
                  <p class="pro_sub">
                    All our Instructors are self-paced and have been designed by
                    subject matter experts, to give you an interactive and
                    enriched learning experience.
                  </p>
                </div>
                <div class="proBanner_sub_cont">
                  <h2>
                    Home <Bs.BsChevronDoubleRight /> Instructors
                  </h2>
                </div>
                <img
                  src={require("../../assets/bg-white.png")}
                  alt=""
                  class="banner_shape1"
                />
              </div>
            </div>
          </section> */}
        </header>
        {/* header ends */}
        {/* main starts  */}
        <main>
          <section class="instructors">
            <div class="container">
              <h1
                class="page_head_title"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Instructors.
              </h1>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-1.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Robert K. Johanson</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-2.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Anna Kunytska</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1500"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-3.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Ben Daniel</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-4.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Eric L. Shipman</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1500"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-5.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Kimberley D. Castro</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="2000"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-6.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Chris C. Wallace</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-7.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Kelly W. Frazier</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1500"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-8.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Harry J. Bryant</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card_1"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="2000"
                  >
                    <div class="card-img-top">
                      <img
                        src={require("../../assets/instructors/instructor-9.png")}
                        alt="..."
                      />
                    </div>

                    <div class="card-body">
                      <h2 class="card-title">Lester M. Brunetti</h2>
                      <h6>Teacher</h6>
                      <p class="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                    <div class="card-body">
                      <div className="social-2 d-flex">
                        <a href="/" className="fb">
                          <Fa.FaFacebookF />
                        </a>
                        <a href="/" className="twt">
                          <Bs.BsTwitter />
                        </a>
                        <a href="/" className="yt">
                          <Bs.BsYoutube />
                        </a>
                        <a href="/" className="linkin">
                          <Fa.FaLinkedinIn />
                        </a>
                        <a href="/" className="insta">
                          <Bs.BsInstagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bgg"></div>
            <div class="bgg2"></div>
            <div class="bgg3"></div>
          </section>
          <BottomBar />
        </main>
        {/* main ends */}
        {/* footer starts */}
        <footer>
          <Footer />
        </footer>
        {/* footer ends */}
      </Transitions>
    </>
  );
}

export default Instructor;
