import { useState } from "react";
import { APIEndpoints } from "../constants/ApiEndPoints";
import BaseClient from "../helpers/base_client";
const useUserProfile = () => {
  const [actionLoading, setActionLoading] = useState({
    action: "",
    loading: false,
  });
  /* update details */
  const updateUser = async (data, { onSuccess, onFailed }) => {
    try {
      setActionLoading({ action: "Edit", loading: true });

      await BaseClient.put(APIEndpoints.updateUser, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading({ action: "", loading: false });
    }
  };
  return {
    updateUser,
    actionLoading,
  };
};
export default useUserProfile;
