import validator from "validator";

class Validator {
  static validateEmail(email) {
    //Validates the email address
    return /\S+@\S+\.\S+/.test(email);
  }

  static validatePhone(phone) {
    //Validates the phone number
    return validator.isMobilePhone(phone);
  }

  // static validateName(name) {
  //   return /^[a-zA-Z]+$/.test(name);
  // }
}

export default Validator;
