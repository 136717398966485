import React, { useContext } from "react";
import "./ForgotPassword.css";
import { useState } from "react";
import * as Ai from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router";
import toast, { Toaster } from "react-hot-toast";

function ConfirmPassword() {
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { resetPassword } = useContext(AppContext);
  const navigate = useNavigate();
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handlePassword = async (e) => {
    e.preventDefault();
    const userId = sessionStorage.getItem("userId");
    if (password !== confirmPassword) {
      toast.error("Password do not match");
      return;
    }
    const payload = {
      userId: userId,
      password: password,
    };
    await resetPassword(payload, {
      onSuccess: (res) => {
        console.log(res.message);
        toast.success("Successfully Changed password");
        navigate("/auth");
      },
      onFailed: (err) => {
        console.log(err.message);
      },
    });
  };
  return (
    <div className="container-fluid">
      <Toaster position="top-center" />
      <div class="log_logo ali-logo">
        <img src={require("../../assets/logo_main.png")} alt="" />
      </div>
      <div className="card forgt-card">
        <div class="log_cont card-pass-f">
          <h6 className="for-pass mt-3 mb-3">Reset Your Password</h6>
          <form onSubmit={(e) => handlePassword(e)}>
            <div className="form-group">
              <label htmlFor="" className="form-label forget_label">
                New Password
              </label>
              <input
                type="text"
                name="password"
                id=""
                onChange={(e) => setPassword(e.target.value)}
                class="form_field password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="" className="form-label forget_label">
                Confirm Password
              </label>
              <div className="forget_input">
                <input
                  type={passwordType}
                  name="confirmpassword"
                  class="form_field password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <i
                  className="pass_eye"
                  onClick={togglePassword}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType === "password" ? (
                    <Ai.AiFillEyeInvisible />
                  ) : (
                    <Ai.AiFillEye />
                  )}
                </i>
              </div>
            </div>
            <button className="form_btn break" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPassword;
