import React, { useState } from "react";
import BaseClient from "../helpers/base_client";
import { APIEndpoints } from "../constants/ApiEndPoints";

const usePassword = () => {
  const [passwordloading, setPasswordLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  // for email
  const forgotPassword = async (email, { onSuccess, onFailed }) => {
    try {
      setPasswordLoading(true);
      await BaseClient.post(
        APIEndpoints.forgotPassword,
        { email },
        {
          onSuccess: onSuccess,
          onFailed: onFailed,
        }
      );
    } finally {
      setPasswordLoading(false);
    }
  };
  // for OTP
  const otpVerification = async (payload, { onSuccess, onFailed }) => {
    try {
      setIsLoading(true);
      await BaseClient.post(APIEndpoints.otpVerification, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setIsLoading(false);
    }
  };
  // for resetpassword
  const resetPassword = async (payload, { onSuccess, onFailed }) => {
    try {
      setOnLoading(true);
      await BaseClient.post(APIEndpoints.resetPassword, payload, {
        onSuccess: onSuccess,
        onFailed,
        onFailed,
      });
    } finally {
      setOnLoading(false);
    }
  };
  return {
    forgotPassword,
    loading: passwordloading,
    isLoading,
    otpVerification,
    resetPassword,
  };
};

export default usePassword;
