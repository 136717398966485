
export default class CustomErrors {
  static USER_CREDENTIALS_ERROR =
    "We apologize, but we are unable to process your request to join due to invalid credentials or system error";
  static USER_NOT_BOOKED_ERROR =
    "We're sorry, but you are unable to join the session as it has not been booked or scheduled. Please schedule a session before attempting to join. Thank you.";
  static USER_CONNECTION_LOST_ERROR =
    "We apologize for the inconvenience, but the connection to the server has been lost. Please try reconnecting or contact technical support for further assistance.";
  static USER_BROADCAST_ERROR =
    "We apologize for the interruption during your video session. An unknown error occurred, and we suggest trying to reconnect. If the issue persists, please contact technical support for further assistance.";
  static USER_TOKEN_AUTHORIZATION_ERROR =
    "We apologize, but an error occurred with the user token authorization from Token Service. Please verify your credentials or contact technical support for further assistance.";
  static USER_MEDIA_PERMISSION_ERROR =
    "We're sorry, but it seems that your media permissions are not properly configured on our website. Please grant the necessary permissions or adjust your browser settings and try again. If the issue persists, please contact technical support for further assistance.";
  static USER_NETWORK_TIMEOUT_ERROR =
    "We apologize for the inconvenience, but it seems that a network timeout error has occurred. Please check your internet connection and try again. If the issue persists, please contact technical support for further assistance. Thank you.";
  static UNEXPECTED_ERROR =
    "We apologize for the inconvenience, but it seems that something unexpected has occurred, and our system is unable to process your request at this time. Please try again later or contact technical support for further assistance. Thank you for your understanding.";
  static SESSION_NOT_STARTED =
    "We apologize for the inconvenience, but it seems that the session has not started yet. Please wait for the scheduled start time or contact support for further information. Thank you.";
    static DEVICE_NOT_FOUND =
    "We're sorry, but we were unable to detect a microphone or camera on your device. This may be due to a hardware issue or a problem with your device's settings. Please check that your microphone and camera are properly connected and enabled, and try again.";
  static SESSION_ENDED =
    "We apologize for the inconvenience, but it seems that the session has ended. Please contact the support for further information or to schedule a new session. Thank you.";
    static USER_NOT_FOUND =
    "If you user doesn't has any account in entered mail";
    staticUNEXPECTED_ERROR =
    "an unknown error occured";
}
