import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TherapyConfirmation from "./TherapyConfirmation";
import * as Gr from "react-icons/gr";
import * as Ai from "react-icons/ai";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AppContext } from "../../context/AppContext";
import Payment from "../Checkout/Payment";

function TherapyStepper() {
  const navigate = useNavigate();
  const steps = ["Select a Slot", "Checkout"];

  const { selectedScheduleSlot, activeStep, setActiveStep } =
    useContext(AppContext);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", () => {
  //     sessionStorage.setItem("activeStep", JSON.stringify(activeStep));
  //   });

  //   const step = JSON.parse(sessionStorage.getItem("activeStep"));

  //   const therapyDetails = JSON.parse(
  //     sessionStorage.getItem("selectedTherapy")
  //   );

  //   if (step) {
  //     setActiveStep(step);
  //     return;
  //   }

  //   return () => {
  //     window.removeEventListener("beforeunload", () => {
  //       console.log("Entered")
  //       sessionStorage.removeItem("activeStep");
  //     });
  //   };
  // }, [activeStep]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const confirmAction = () => {
    confirmAlert({
      title: "Confirm Action",
      message: "Are you sure you want to go back?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            window.location.href = "/therapies";
          },
        },
        { label: "No" },
      ],
    });
  };
  const backbuttonAction = () => {
    if (activeStep === 0) {
      confirmAction();
      return;
    }
    handleBack();
  };

  return (
    <>
      <Header />
      <main>
        <section className="therapies">
          <div className="container">
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <button
                  color="inherit"
                  onClick={backbuttonAction}
                  className="stepper-btn btn_status"
                >
                  <i>
                    <Ai.AiOutlineArrowLeft />
                  </i>
                  Prev
                </button>
                <Box sx={{ flex: "1 1 auto" }} />
                <button
                  onClick={handleNext}
                  className="stepper-btn btn_status"
                  disabled={
                    selectedScheduleSlot == null ||
                    activeStep === steps.length - 1
                  }
                >
                  {activeStep === steps.length - 1 ? (
                    <i onClick={handleNext}>
                      <Gr.GrStatusGood />
                    </i>
                  ) : (
                    <>
                      Next
                      <i style={{ color: "#000" }} onClick={handleNext}>
                        <Ai.AiOutlineArrowRight />
                      </i>
                    </>
                  )}
                </button>
              </Box>
              {activeStep === 2 ? (
                <>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                  </Box>
                </>
              ) : (
                <>
                  <br />
                  <div className="container">
                    {activeStep === 0 && (
                      <TherapyConfirmation onClick={handleNext} />
                    )}
                    {activeStep === 1 && selectedScheduleSlot && <Payment />}
                  </div>
                </>
              )}
            </Box>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default TherapyStepper;
