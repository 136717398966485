import React from "react";
import "./SuccessPage.css";
import failed from "../../assets/lottie/14651-error-animation.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router";

function ErrorPage() {
  let navigate = useNavigate();
  const goBackToHome = () => {
    navigate("/");
  };
  return (
    <>
      <section class="success">
        <div class="success_cont">
          <div class="lottie">
            <Lottie animationData={failed} loop={false}></Lottie>
          </div>
          <h4>
            Sorry! There is some issues on booking.
            <br /> Please Try again!
          </h4>
          <button className="home_btn" onClick={goBackToHome}>
            Go back to Home
          </button>
        </div>
      </section>
    </>
  );
}

export default ErrorPage;
