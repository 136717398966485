import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header2/Header2";
import "./Program.css";
import ProgramData from "./ProgramData";
import { motion, AnimatePresence } from "framer-motion";
import BottomBar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// icons
import * as Bs from "react-icons/bs";
import { AiFillInfoCircle } from "react-icons/ai";
import Modal from "./Modal";

function Programme(props) {
  const { value } = props;
  const [programs, setPrograms] = useState([]);
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [active, setActive] = useState("all");
  const [viewMore, setViewMore] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setPrograms([...ProgramData]);
    setCollections([...ProgramData]);
    let categoriesData = [];
    ProgramData.map((x) => {
      categoriesData.push({ category: x.category });
    });
    setCategories([...new Set(categoriesData.map((x) => x.category))]);
  }, []);

  useEffect(() => {
    console.log("Categories", categories);
  }, [categories]);

  const filterProgram = (itemData) => {
    const filterData = ProgramData.filter((item) => item.category === itemData);
    setPrograms(filterData);
  };
  function filterValue(value) {
    const filterData = ProgramData.filter((item) => item.category === value);
    setPrograms(filterData);
    setActive(value);
  }
  const showMore = () => {
    console.log("clicked");
    setViewMore(!viewMore);
  };

  return (
    <>
      <Transitions>
        <Modal open={openModal} onClose={() => setOpenModal(false)} />
        {/* header starts */}
        <Header2 />
        {/* header ends */}
        {/* main starts  */}
        <main>
          <section class="pro_banner">
            <div class="proBanner">
              <div class="container">
                <div class="pb_cont">
                  <h1 class="pro_title">Assesments</h1>
                  <p class="pro_sub">
                    All our Assesments are self-paced and have been designed by
                    subject matter experts, to give you an interactive and
                    enriched learning experience.
                  </p>
                </div>
                <div class="proBanner_sub_cont">
                  <h2>
                    Home <Bs.BsChevronDoubleRight /> Assesments
                  </h2>
                </div>
                <img
                  src={require("../../assets/bg-white.png")}
                  alt=""
                  class="banner_shape1"
                />
              </div>
            </div>
          </section>

          <section class="program">
            <div class="ev_bg1"></div>

            <div class="program_wrapper">
              <div class="filter_item">
                <ul>
                  <li>
                    <button
                      className={
                        active === "all" ? "pro_btn active" : "pro_btn"
                      }
                      onClick={() => {
                        setPrograms(ProgramData);
                        setActive("all");
                      }}
                    >
                      All
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        active === "well Being" ? "pro_btn active" : "pro_btn"
                      }
                      onClick={() => filterValue("well Being")}
                    >
                      Well Being
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        active === "addictions" ? "pro_btn active" : "pro_btn"
                      }
                      onClick={() => filterValue("addictions")}
                    >
                      addictions
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        active === "life Occurrences"
                          ? "pro_btn active"
                          : "pro_btn"
                      }
                      onClick={() => filterValue("life Occurrences")}
                    >
                      Life Occurrences
                    </button>
                  </li>
                </ul>
              </div>
              <div
                class={viewMore ? "cover hide" : "cover"}
                // data-aos="fade-up"
                // data-aos-offset="200"
                // data-aos-delay="60"
                // data-aos-duration="1000"
              >
                <div class="container program_container">
                  {programs.map((item) => {
                    {
                      return (
                        <div className="pro_items" key={item.id} layout>
                          <p>{item.name}</p>
                          <div class="pros">
                            <LazyLoadImage
                              src={item.image}
                              effect="blur"
                              width="100%"
                            />
                          </div>
                          {/* <img src={item.image} /> */}
                          <button onClick={() => setOpenModal(true)}>
                            <AiFillInfoCircle />
                          </button>
                        </div>
                      );
                    }
                  })}
                </div>
                  <div class="ev_bg2"></div>
              </div>
              <div class="show_more">
                <button type="button" onClick={showMore}>
                  {viewMore ? "Hide" : "Show more"}
                </button>
              </div>
            </div>
          </section>
          <BottomBar />
          <section class="free_month">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8">
                  <div class="free_month_cont">
                    <h1>
                      Start My <span>Free Month</span>
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Veniam quia nihil aliquid quasi maxime, quis impedit
                      dolorum beatae cum dicta.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <button class="free_month_btn">
                    Start My Free Month <Bs.BsChevronRight />
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* main ends */}
        {/* footer starts */}
        <footer>
          <Footer />
        </footer>
        {/* footer ends */}
      </Transitions>
    </>
  );
}

export default Programme;
