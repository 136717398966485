import React from "react";
import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/Footer/Footer";
import BottomBar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";
import "./Blog.css";

import * as Bs from "react-icons/bs";
import * as Md from "react-icons/md";
import { AiFillPushpin } from "react-icons/ai";

function Blog() {
  return (
    <>
      <Transitions>
        <header>
          <Header2 />
          {/* banner section */}
          <section class="pro_banner">
            <div class="proBanner">
              <div class="container">
                <div class="pb_cont">
                  <h1 class="pro_title">Blog</h1>
                  <p class="pro_sub">
                    All our Blog are self-paced and have been designed by
                    subject matter experts, to give you an interactive and
                    enriched learning experience.
                  </p>
                </div>
                <div class="proBanner_sub_cont">
                  <h2>
                    Home <Bs.BsChevronDoubleRight /> Blog
                  </h2>
                </div>
                <img
                  src={require("../../assets/bg-white.png")}
                  alt=""
                  class="banner_shape1"
                />
              </div>
            </div>
          </section>
          {/* banner section end */}
        </header>
        {/* header End */}
        <main>
          <section class="blog_area">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-6">
                  <div class="card bl_card">
                    <div className="card-img-top">
                      <img src={require("../../assets/blog/blog2.jpg")} />
                      <div className="overlay">
                        <span className="vline"></span>
                        <span className="vline-bottom"></span>
                      </div>
                    </div>
                    <div class="card-body bl_cont">
                      <h5 class="card-title">
                        Learning, friendship and fun for everyone
                      </h5>
                      <div class="bl_ico d-flex">
                        <div class="bl_date d-flex">
                          <i>
                            <Md.MdDateRange />
                          </i>
                          <p>20th January</p>
                        </div>
                        <div class="bl_date d-flex">
                          <i>
                            <Bs.BsFolder2Open />
                          </i>
                          <p>Education</p>
                        </div>
                      </div>
                      <p class="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab eligendi porro magnam veniam autem, voluptatum
                        doloremque magni sint. Sapiente quis sequi optio
                        praesentium asperiores soluta incidunt, natus doloremque
                        fugit quam.
                      </p>
                    </div>
                  </div>
                  {/* card end */}

                  <div class="card bl_card">
                    <div className="card-img-top">
                      <img src={require("../../assets/blog/blog4.jpg")} />
                      <div className="overlay">
                        <span className="vline"></span>
                        <span className="vline-bottom"></span>
                      </div>
                    </div>
                    <div class="card-body bl_cont">
                      <h5 class="card-title">
                        Tips to developing a quality discussion
                      </h5>
                      <div class="bl_ico d-flex">
                        <div class="bl_date d-flex">
                          <i>
                            <Md.MdDateRange />
                          </i>
                          <p>5th May</p>
                        </div>
                        <div class="bl_date d-flex">
                          <i>
                            <Bs.BsFolder2Open />
                          </i>
                          <p>Education</p>
                        </div>
                      </div>
                      <p class="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab eligendi porro magnam veniam autem, voluptatum
                        doloremque magni sint. Sapiente quis sequi optio
                        praesentium asperiores soluta incidunt, natus doloremque
                        fugit quam.
                      </p>
                    </div>
                  </div>
                  {/* card end */}

                  <div class="card bl_card">
                    <div className="card-img-top">
                      <img src={require("../../assets/blog/blog5.jpg")} />
                      <div className="overlay">
                        <span className="vline"></span>
                        <span className="vline-bottom"></span>
                      </div>
                    </div>
                    <div class="card-body bl_cont">
                      <h5 class="card-title">
                        Designer bag bingo – new choices fundraiser
                      </h5>
                      <div class="bl_ico d-flex">
                        <div class="bl_date d-flex">
                          <i>
                            <Md.MdDateRange />
                          </i>
                          <p>3rd October</p>
                        </div>
                        <div class="bl_date d-flex">
                          <i>
                            <Bs.BsFolder2Open />
                          </i>
                          <p>Education</p>
                        </div>
                      </div>
                      <p class="card-text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ab eligendi porro magnam veniam autem, voluptatum
                        doloremque magni sint. Sapiente quis sequi optio
                        praesentium asperiores soluta incidunt, natus doloremque
                        fugit quam.
                      </p>
                    </div>
                  </div>
                  {/* card end */}
                </div>
                <div class="col-sm-4 col-md-4 col-sm-6">
                  <div class="container">
                    <div class="bl_search">
                      <input type="search" name="" id="" />
                      <i>
                        <Bs.BsSearch />
                      </i>
                    </div>
                    <div class="related">
                      <h2>Related Courses</h2>
                      <div class="bl_relates">
                        <h3>
                          <i>
                            <Bs.BsPinAngle />
                          </i>{" "}
                          Complete Yoga Masterclass
                        </h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quisquam adipisci, ipsa alias veritatis nam
                          nihil laudantium vero expedita dolores minus fugit
                          laboriosam iure aliquid possimus. Aliquid sequi earum
                          nostrum ad.
                        </p>
                        <div class="rel_date d-flex">
                          <i>
                            <Md.MdDateRange /> 20/02/2023
                          </i>
                        </div>
                      </div>
                      {/* related end */}
                      <div class="bl_relates">
                        <h3>
                          <i>
                            <Bs.BsPinAngle />
                          </i>{" "}
                          Summer Wisdom Course Starts
                        </h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quisquam adipisci, ipsa alias veritatis nam
                          nihil laudantium vero expedita dolores minus fugit
                          laboriosam iure aliquid possimus. Aliquid sequi earum
                          nostrum ad.
                        </p>
                        <div class="rel_date d-flex">
                          <i>
                            <Md.MdDateRange /> 11/05/2023
                          </i>
                        </div>
                      </div>
                      {/* related end */}
                      <div class="bl_relates">
                        <h3>
                          <i>
                            <Bs.BsPinAngle />
                          </i>{" "}
                          New Yoga Online Courses starts
                        </h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Quisquam adipisci, ipsa alias veritatis nam
                          nihil laudantium vero expedita dolores minus fugit
                          laboriosam iure aliquid possimus. Aliquid sequi earum
                          nostrum ad.
                        </p>
                        <div class="rel_date d-flex">
                          <i>
                            <Md.MdDateRange /> 20/05/2023
                          </i>
                        </div>
                      </div>
                      {/* related end */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <BottomBar />
        </main>
        {/* main end */}
        <footer>
          <Footer />
        </footer>
        {/* footer end */}
      </Transitions>
    </>
  );
}

export default Blog;
