import React, { useState, useEffect } from "react";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Therapies from "./pages/Programme/Programme";
import Instructor from "./pages/Instructor/Instructor";
import Events from "./pages/Events/Events";
import { PropagateLoader } from "react-spinners";
import "./App.css";
import About from "./pages/About/About";
import ScrollToTop from "./ScrollToTop";
import BackToTop from "./components/BackToTop/BackToTop";
import HashLoader from "react-spinners/HashLoader";
import Blog from "./pages/Blog/Blog";
import Auth from "./pages/Auth/Auth";
import AppRoutes from "./routes/routes";
import { ProtectedRoute, ProtectedAuthRoute } from "./routes/protector";
import Profile from "./pages/Profile/Profile";
import { Circles } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/Circles";
import Transitions from "./Transition";
import { Toaster } from "react-hot-toast";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="App">
        {isLoading ? (
          <div className="loader">
            {/* <HashLoader color={"#c4d4bc"} loading={isLoading} size={100} /> */}
            <Circles
              height="150"
              width="150"
              color="#c4d4bc"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Transitions>
            <Router>
              <ScrollToTop />
              <Routes>
                {AppRoutes.map((route, index) => {
                  return !route.isProtected && !route.isProtectedAuth ? (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                      exact="true"
                    />
                  ) : !route.isProtected && route.isProtectedAuth ? (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        <ProtectedAuthRoute>{route.element}</ProtectedAuthRoute>
                      }
                      exact="true"
                    />
                  ) : (
                    <Route
                      key={index}
                      path={route.path}
                      element={<ProtectedRoute>{route.element}</ProtectedRoute>}
                      exact="true"
                    />
                  );
                })}
              </Routes>
              <BackToTop />
            </Router>
          </Transitions>
        )}
      </div>
    </>
  );
}

export default App;
