import React, {
  useState,
  useContext,
  useEffect,
  useImperativeHandle,
} from "react";
import { useParams, useNavigate } from "react-router";
import * as Ai from "react-icons/ai";
import * as Md from "react-icons/md";
import * as Bs from "react-icons/bs";
import * as IoMd from "react-icons/io";
import * as Fa from "react-icons/fa";
import * as Im from "react-icons/im";
import { Modal } from "antd";
import useTheraphy from "../../hooks/useTheraphy";
import { Row } from "react-bootstrap";
import useAgora from "../../hooks/useAgora";
import "./LiveSession.css";
import imagePlaceholder from "../../assets/placeholder01.jpg";
import MediaPlayer from "../../pages/StreamPage/Mediaplayer";
import ChatSession from "./ChatSession";
import CircleLoader from "../common/CircleLoader";
import { AppContext } from "../../context/AppContext";
import Webcam from "react-webcam";
import StreamErrors from "../StreamErrors/StreamErrors";
import toast, { Toaster } from "react-hot-toast";

function LiveSession() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [mainPlayerSrc, setMainPlayerSrc] = useState({});
  const [sidePlayerSrc, setSidePlayerSrc] = useState(null);
  const [overlayPlayerSrc, setOverlayPlayerSrc] = useState({});
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [currentTherapy, setCurrentTherpy] = useState(null);
  const [therapyCategory, setTherapyCategory] = useState(null);
  const [sidePlayers, setSidePlayers] = useState([]);
  const {
    remoteUsers,
    bookedTherapies,
    getUserListOfSchedule,
    setRemoteUsers,
  } = useContext(AppContext);

  const mainPlayerRef = React.useRef();

  const params = useParams();

  const {
    localTracks,
    leave,
    joinState,
    remotePublisher,
    toggleScreenShare,
    localScreenTracks,
    sharingScreen,
    joining,
    agoraError,
    toggleCamera,
    toggleMic,
    muted,
    videoHide,
    audioDevices,
    videoDevices,
    customAVTrackFromDevices,
  } = useAgora({
    channelId: params.id,
    onSharingScreenEnded: () => {
      const element = document.getElementById("screensharebtn");
      if (element != null && element.classList != null) {
        element.classList.remove("active-class");
      }
    },
    onSelectVideoDevice: (device) => {
      setSelectedVideoDevice(device);
    },
    onSelectAudioDevice: (device) => {
      setSelectedAudioDevice(device);
    },
    onUserLeave: (uid) => {
      handleUserLeave();
    },
  });
  const { sessionStopped } = useTheraphy();
  const handleUserLeave = (uid) => {
    const element = document.getElementById(`${uid}_player`);
    console.log("Element", element);
  };

  const handleMicrophoneClick = async () => {
    const element = document.getElementById("microphoneBtn");
    if (element != null && element.classList != null) {
      if (muted == false) {
        await toggleMic();
        element.classList.toggle("active-class");
      } else {
        await toggleMic();
        element.classList.remove("active-class");
      }
    }
  };

  const handleVideoClick = async () => {
    const element = document.getElementById("videobtn");
    if (element != null && element.classList != null) {
      if (videoHide == false) {
        await toggleCamera();
        element.classList.toggle("active-class");
      } else {
        await toggleCamera();
        element.classList.remove("active-class");
      }
    }
  };

  const handleScreenShareClick = async () => {
    const element = document.getElementById("screensharebtn");
    if (element != null && element.classList != null) {
      if (sharingScreen == false) {
        await toggleScreenShare();
        element.classList.toggle("active-class");
      } else {
        await toggleScreenShare();
        element.classList.remove("active-class");
      }
    }
  };

  const handleLeaveButtonClick = () => {
    leave({
      onUserLeave: (uid) => {},
    });

    setTimeout(() => {
      navigate("/leavepage");
    }, 800);
  };

  useEffect(() => {
    getUserListOfSchedule(params.id);
  }, []);

  useEffect(() => {
    initFunction();
  }, [bookedTherapies]);

  useEffect(() => {
    getMainPlayerTrack();
    getSidePlayerSource();
  }, [remoteUsers, remotePublisher, localTracks]);

  useEffect(() => {
    console.log("MainPlayer", mainPlayerSrc);
  }, [mainPlayerSrc]);

  useEffect(() => {
    console.log("SidePlayerSrc", sidePlayerSrc);
  }, [sidePlayerSrc]);

  useEffect(() => {
    if (!currentTherapy) return;
    const categoryData = currentTherapy.theraphyDetails.category;
    setTherapyCategory(categoryData);

    console.log("currentTherapy", currentTherapy);
  }, [currentTherapy]);

  const getMainPlayerTrack = () => {
    setMainPlayerSrc({});

    let tracks = {};
    if (remotePublisher != null) {
      tracks.videoTrack = remotePublisher.videoTrack;
      tracks.audioTrack = remotePublisher.audioTrack;
      tracks.uid = remotePublisher.uid;
    } else if (
      remotePublisher != null &&
      remotePublisher.videoTrack == null &&
      remotePublisher.audioTrack == null
    ) {
      tracks.videoTrack = localTracks.localVideoTrack;
      tracks.audioTrack = null;
      tracks.uid = localTracks.uid;
    } else if (remotePublisher == null && sharingScreen == true) {
      tracks.videoTrack = localScreenTracks;
      tracks.audioTrack = localTracks.localAudioTrack;
      tracks.uid = localTracks.uid;
    } else {
      tracks.videoTrack = localTracks.localVideoTrack;
      tracks.audioTrack = null;
      tracks.uid = localTracks.uid;
    }

    if (Object.keys(tracks).length == 0) return;

    setMainPlayerSrc({ ...tracks });
  };

  const getSidePlayerSource = () => {
    setSidePlayerSrc({});

    let tracks = {};
    if (remotePublisher != null) {
      tracks.videoTrack = localTracks.localVideoTrack;
      tracks.audioTrack = null;
      tracks.uid = localTracks.uid;
    } else if (remotePublisher != null && sharingScreen == true) {
      tracks.videoTrack = localScreenTracks;
      tracks.audioTrack = localTracks.localAudioTrack;
      tracks.uid = localTracks.uid;
    }

    if (Object.keys(tracks).length == 0) return;

    setSidePlayerSrc({ ...tracks });
  };

  useEffect(() => {
    remoteUsers.map((users) => {
      const id = users.uid;
      console.log(id.substring, "userID");
    });
  }, [remoteUsers]);

  //?? Switch Player data have index, videoTrack, audioTrack, uid & player from ChatSession

  const onClickPinBtn = ({ index, uid, player }) => {
    let remoteUserIndex = -1;
    let remoteUser = null;

    for (let index = 0; index < remoteUsers.length; index++) {
      const xRemoteUser = remoteUsers[index];
      if (xRemoteUser.uid == uid) {
        remoteUser = { ...xRemoteUser };
        remoteUserIndex = index;
        break;
      }
    }

    if (remoteUserIndex == -1 || remoteUser == null) return;

    const tempMainPlayerSrc = { ...mainPlayerSrc };

    tempMainPlayerSrc.uid = remoteUser.uid;
    tempMainPlayerSrc.videoTrack = remoteUser.videoTrack;
    tempMainPlayerSrc.audioTrack = remoteUser.audioTrack;
    tempMainPlayerSrc.updated = true;

    remoteUser.uid = mainPlayerSrc.uid;
    remoteUser.videoTrack = mainPlayerSrc.videoTrack;
    remoteUser.audioTrack = mainPlayerSrc.audioTrack;

    remoteUsers[remoteUserIndex] = remoteUser;

    setMainPlayerSrc({ ...tempMainPlayerSrc });
    setRemoteUsers(remoteUsers);

    // console.log("remoteUserIndex", remoteUserIndex);
  };

  const filterDuplicatedUser = (remoteUsers) => {
    const filteredRemotePlayers = [];

    for (let index = 0; index < remoteUsers.length; index++) {
      const remoteUser = remoteUsers[index];
      if (filteredRemotePlayers.find((x) => x.uid == remoteUser.uid)) {
        continue;
      }
      filteredRemotePlayers.push(remoteUser);
    }

    return filteredRemotePlayers;
  };

  const checkMainPlayerUidMatches = (uid) =>
    mainPlayerSrc.uid != null && mainPlayerSrc.uid === uid;

  useEffect(() => {
    console.log("PlayerSrc", sidePlayerSrc);
  }, [sidePlayerSrc]);

  const handleVideoOutput = (videoId) => {
    const videoSource = videoDevices.find((x) => x.deviceId == videoId);
    setSelectedVideoDevice(videoSource);
  };

  const handleAudioOutput = (audioId) => {
    const audioSource = audioDevices.find((x) => x.deviceId == audioId);
    setSelectedAudioDevice(audioSource);
  };

  const handleAllSettings = async () => {
    await customAVTrackFromDevices(
      selectedVideoDevice,
      selectedAudioDevice,
      () => {
        setShowSettingsModal(false);
      }
    );
  };

  const initFunction = () => {
    const id = params.id;
    if (
      !bookedTherapies ||
      !Array.isArray(bookedTherapies) ||
      bookedTherapies.length == 0
    ) {
      return;
    }

    const therapyData = bookedTherapies.find((therapy) => therapy._id == id);
    setCurrentTherpy(therapyData);
  };

  const addPlayerToList = (player) => {
    setSidePlayers([...sidePlayers, player]);
  };

  return (
    <>
      <Toaster position="top-center" />

      {joining == false && joinState == true && agoraError == null && (
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <main>
              {currentTherapy != null && (
                <Modal
                  distryOnClose={true}
                  onCancel={() => setShowInfoModal(false)}
                  onOk={() => setShowInfoModal(false)}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{
                    style: {
                      backgroundColor: "#249782",
                      transition: "all 0.2s ease-in-out",
                      width: "50%",
                      margin: "0 auto",
                      display: "block",
                    },
                  }}
                >
                  <p className="modal_head">Therapy Details</p>
                  <h4
                    className="modal_names"
                    title="Yoga begginers course -  Special class for all members"
                  >
                    {currentTherapy.theraphyDetails.therapyName
                      ? currentTherapy.theraphyDetails.therapyName
                      : "Not Assigned"}
                  </h4>
                  <p class="modal_sub">{"Unknown"}</p>
                  <p>{currentTherapy.theraphyDetails.therapyDescription}</p>
                  <hr />
                  <div class="row">
                    <div class="col-6">
                      <p class="modal_head">Therapist Details</p>
                      <h4>{currentTherapy.therapistDetails.name}</h4>
                      <p class="modal_sub">
                        {currentTherapy.theraphyDetails.desigination}
                      </p>
                      <p class="therapist_modal_details">
                        <Ai.AiOutlineMail />{" "}
                        {currentTherapy.therapistDetails.email}
                      </p>
                    </div>
                    <div class="col-6">
                      <div class="therapist_profile">
                        <img
                          src={
                            currentTherapy.therapistDetails.photo
                              ? process.env.REACT_APP_AWS_IMAGE_URL +
                                currentTherapy.therapistDetails.photo
                              : imagePlaceholder
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
              {/* Settings Modal */}
              <Modal
                open={showSettingsModal}
                onCancel={() => setShowSettingsModal(false)}
                onOk={() => setShowSettingsModal(false)}
                footer={null}
                centered={true}
              >
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="settings-screen">
                      {selectedVideoDevice != null ? (
                        <Webcam
                          height={150}
                          width="100%"
                          videoConstraints={selectedVideoDevice}
                        />
                      ) : (
                        <img
                          src={require("../../assets/nest_placeholder.jpg")}
                        />
                      )}
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <label for="">Camera</label>
                    <select
                      name=""
                      id=""
                      class="settings-select mt-2 mb-2"
                      onChange={(e) => handleVideoOutput(e.target.value)}
                      value={
                        selectedVideoDevice != null &&
                        selectedVideoDevice.deviceId != null
                          ? selectedVideoDevice.deviceId
                          : null
                      }
                    >
                      <option
                        className="setting-options"
                        value=""
                        selected
                        hidden
                      >
                        &#xf030; <span className="ps-2">Choose Camera</span>
                      </option>
                      {videoDevices != null &&
                        videoDevices.map((videoSource) => {
                          return (
                            <option
                              className="setting-options"
                              value={videoSource.deviceId}
                              key={videoSource.deviceId}
                            >
                              {videoSource.label}
                            </option>
                          );
                        })}
                    </select>
                    <label for="">Mic</label>
                    <select
                      name=""
                      id=""
                      class="settings-select mt-2"
                      onChange={(e) => handleAudioOutput(e.target.value)}
                      value={
                        selectedAudioDevice != null &&
                        selectedAudioDevice.deviceId != null
                          ? selectedAudioDevice.deviceId
                          : null
                      }
                    >
                      <option
                        className="setting-options"
                        value=""
                        selected
                        hidden
                      >
                        &#xf130; <span className="ps-2">Choose Microphone</span>
                      </option>
                      {audioDevices != null &&
                        audioDevices.map((audioSource, index) => {
                          return (
                            <option
                              className="setting-options"
                              value={audioSource.deviceId}
                              key={index}
                            >
                              {audioSource.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div class="button-grp d-flex mt-2">
                  <button class="set_btn ok" onClick={handleAllSettings}>
                    OK
                  </button>
                  <button
                    class="set_btn cancel ms-2"
                    onClick={() => setShowSettingsModal(false)}
                  >
                    cancel
                  </button>
                </div>
              </Modal>
              <section className="live-streaming">
                <div class="container">
                  <Row>
                    {currentTherapy != null && (
                      <div className="d-flex ps-0">
                        <button
                          className="button-back"
                          onClick={() => {
                            window.location.href = "/profile";
                          }}
                        >
                          <Md.MdArrowBackIosNew />
                        </button>

                        <div className="mt-2 ms-2 d-flex">
                          <h5>
                            {currentTherapy.theraphyDetails.therapyName
                              ? currentTherapy.theraphyDetails.therapyName
                              : "Not Assigned"}
                          </h5>
                          <div
                            class="classCard"
                            onClick={() => setShowInfoModal(true)}
                          >
                            <Bs.BsInfoCircleFill id="class_info" />
                            <p>Therapy Details</p>
                          </div>
                        </div>
                      </div>
                    )}

                    <hr className="mt-2" />
                    <div>
                      {sessionStopped && (
                        <div class="alert alert-danger">
                          Therapy session has ended!
                        </div>
                      )}
                    </div>
                    <div className="class_screen">
                      <div className="screen" id="main-screen">
                        {/* <div className="listner">
                          <p>You</p>
                        </div> */}
                        {/* <div className="user_name admin">
                          <span>
                            <i>
                              
                            </i>{" "}
                            {currentTherapy.therapistDetails.name}
                          </span>
                        </div> */}
                        <MediaPlayer
                          id={"localPlayer"}
                          videoTrack={mainPlayerSrc.videoTrack}
                          audioTrack={mainPlayerSrc.audioTrack}
                          style={{ aspectRatio: "16/9" }}
                          ref={mainPlayerRef}
                          mainPlayer={false}
                          adminName={currentTherapy.therapistDetails.name}
                        />

                        {/* {showAlert && (
                          <div class="info_alert d-flex" id="info-alert">
                            <p>Manigandan Joined</p>
                          </div>
                        )} */}

                        {/* <OverlayPlayer
                          videoTrack={overlayPlayerSrc.videoTrack}
                          audioTrack={overlayPlayerSrc.audioTrack}
                          style={{ aspectRatio: "16/9" }}
                        /> */}
                      </div>
                    </div>
                  </Row>
                  <hr />
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3">
                      <div class="stream_logo mt-4">
                        <img
                          src={require("../../assets/logo-web.png")}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="controllers">
                        <div class="cntrl_btn">
                          <button
                            id="microphoneBtn"
                            className="control-btn"
                            onClick={handleMicrophoneClick}
                          >
                            <Bs.BsFillMicMuteFill className="controller_btn_ico" />
                          </button>
                          <label for="Mic" class="controller-label">
                            Mic
                          </label>
                        </div>
                        <div class="cntrl_btn">
                          <button
                            id="videobtn"
                            className="control-btn"
                            onClick={handleVideoClick}
                          >
                            <Fa.FaVideoSlash className="controller_btn_ico" />
                          </button>
                          <label for="Video" class="controller-label">
                            Video
                          </label>
                        </div>
                        <div class="cntrl_btn">
                          <button
                            className="control-btn hungup"
                            onClick={handleLeaveButtonClick}
                          >
                            <Im.ImPhoneHangUp className="controller_btn_ico" />
                          </button>
                          <label for="Video" class="controller-label mt-2">
                            Leave
                          </label>
                        </div>
                        <div class="cntrl_btn">
                          <button
                            id="screensharebtn"
                            className="control-btn"
                            onClick={() => {
                              toast.success("This feature is not available 🙂");
                              //handleScreenShareClick
                            }}
                          >
                            <Md.MdOutlineScreenShare className="controller_btn_ico" />
                          </button>
                          <label for="Screen Share" class="controller-label">
                            Share
                          </label>
                        </div>
                        <div class="cntrl_btn">
                          <button
                            className="control-btn"
                            onClick={() => setShowSettingsModal(true)}
                          >
                            <IoMd.IoMdSettings className="controller_btn_ico" />
                          </button>
                          <label for="Settings" class="controller-label">
                            Settings
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div className="text-center">
              <ChatSession
                remoteUsers={remoteUsers}
                remotePublisher={remotePublisher}
                sidePlayerSrc={sidePlayerSrc}
                onClickPin={onClickPinBtn}
                onPlayerReady={addPlayerToList}
                checkMainPlayerUidMatches={checkMainPlayerUidMatches}
                chatRoomId={params.id}
                mainPlayerSrc={mainPlayerSrc}
              />
            </div>
          </div>
        </div>
      )}
      {joining == true && agoraError == null && (
        <>
          <CircleLoader type="lottieLoader" height="400px" width="400px" />
        </>
      )}
      {agoraError != null && (
        <>
          <StreamErrors title="Failed To Join Session" content={agoraError} />
        </>
      )}
    </>
  );
}

export default LiveSession;
