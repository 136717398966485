import { useState, useEffect } from "react";

const useForm = (initialValues, validator) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [intial, setIntial] = useState(false);

  useEffect(() => {
    if (intial) {
      formValidator();
    }
  }, [formValues]);

  const formValidator = () => {
    let result = validator(formValues);
    setFormErrors(result);
  };

  const onChange = (e) => {
    setIntial(true);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return [formValues, formErrors, formValidator, (e) => onChange(e)];
};

export default useForm;
