import React from "react";
import "./AlertModal.css";
import * as Ai from "react-icons/ai";

function AlertModal(props) {
  if (!props.openAlertModal) return null;
  return (
    <>
      <div class="alert_modal_wrapper">
        <div
          class={
            props.type == "info" ? "modal_container info" : "modal_container"
          }
        >
          <div class="close_btn close" onClick={props.onCloseAlertModal}>
            <Ai.AiFillCloseCircle />
          </div>
          <h2>{props.title}</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis,
            perferendis. 
          </p>
        </div>
      </div>
    </>
  );
}

export default AlertModal;
