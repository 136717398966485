import React, { useState, useEffect } from "react";
import { useContext } from "react";
import * as Ai from "react-icons/ai";
import { BsAlarm } from "react-icons/bs";
import { useNavigate } from "react-router";
import Lottie from "lottie-react";
import AnimatedData from "../../assets/lottie/53391-yelly-emoji-no.json";
import { AppContext } from "../../context/AppContext";
import Login from "../../components/Login/Login";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function Modal({ open, onClose, selectedTherapy }) {
  let navigate = useNavigate();
  const [timeCheck, setTimeCheck] = useState("");
  const [buttonState, setButtonState] = useState(true);
  const [showIns, setShowIns] = useState(false);
  const [date, setDate] = useState(new Date());

  const { findScheduleByDate, selectedDateSchedules } = useContext(AppContext);

  if (!open) return null;

  const findSchedule = (date) => {
    console.log("selectedTherapy", selectedTherapy);
    if (!selectedTherapy || !selectedTherapy.therapyId) return;
    findScheduleByDate({ date, therapyId: selectedTherapy.therapyId });
  };

  const setButtonActive = (e) => {
    setTimeCheck(e.currentTarget.id);
    if (timeCheck == "t1" || "t2" || "t3" || "t4") {
      setButtonState(false);
      setShowIns(true);
    } else if (timeCheck == " ") {
      setButtonState(true);
    }
    console.log("changes");
  };

  const checkoutDir = () => {
    navigate("/payment");
  };
  // const goToLogin = () => {
  //   setIsSignUp(false);
  //   navigate("/auth");
  // };

  return (
    <>
      <div class="modal_wrapper">
        <div class="modal_container">
          <div class="close" onClick={onClose}>
            <Ai.AiOutlineClose />
          </div>

          <div class="modal_cont">
            <h1>Medtiation Classes</h1>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum
              incidunt eaque, aliquid voluptatum libero nisi reprehenderit
              suscipit. Repudiandae, obcaecati. Quidem dolor officiis optio
              autem deserunt laboriosam omnis, nobis nulla dolorum.
            </p>
            <div class="card">
              <Calendar
                value={date}
                className="nest_calander"
                onClickDay={(date) => {
                  console.log("Selected Date", date);
                }}
              />
            </div>
            <br />
            <h4 className="price">$650</h4>
            <h5>Scheduled Time</h5>
            <div class="btn-grp d-flex">
              {selectedDateSchedules &&
                selectedDateSchedules.length > 0 &&
                selectedDateSchedules[0].schedules.map((schedule) => {
                  return (
                    <button
                      class={timeCheck === "t1" ? "times active" : "times"}
                      id="t1"
                      onClick={setButtonActive}
                    >
                      {schedule.startTime} - {schedule.endTime}
                    </button>
                  );
                })}
            </div>
            <div class="instructor_list">
              <hr />
              <br />
              <div class={showIns ? "ins_profile hide" : "ins_profile"}>
                <div class="ins_pic">
                  <img
                    src={require("../../assets/instructors/instructor-2.png")}
                  />
                </div>
                <div class="ins_cont">
                  <h2 id="ins_title">Jinsy Jacob</h2>
                  <p id="ins_cont">
                    Category: <span>Meditation</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="PopBtn_grp d-flex">
              <button
                className="checkout_pop"
                disabled={buttonState}
                onClick={checkoutDir}
              >
                Buy Now
              </button>
              <button className="checkout_pop cancel" onClick={onClose}>
                Cancel
              </button>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
