import React, { useContext, useEffect } from "react";
import "./SuccessPage.css";
import successLottie from "../../assets/lottie/95088-success.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router";
import { AppContext } from "../../context/AppContext";

function SuccessPage() {
  let navigate = useNavigate();
  const { getBookedScheduleOfUser } = useContext(AppContext);

  const goBackToHome = () => {
    getBookedScheduleOfUser();
    window.location.href = "/";

    sessionStorage.removeItem('selectedTherapy');
  };
  return (
    <>
      <main>
        <section class="success">
          <div class="success_cont">
            <div class="lottie">
              <Lottie animationData={successLottie} loop={false}></Lottie>
            </div>
            <h4>Your Therapy session has been Booked</h4>
            <button className="home_btn" onClick={goBackToHome}>
              Go back to Home
            </button>
          </div>
        </section>
      </main>
    </>
  );
}

export default SuccessPage;
