import React, { useContext, useEffect } from "react";
import "./Profile.css";
import { useState } from "react";
import Header from "../../components/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import Profile from "../../assets/instructors/placeholder.png";
import "react-confirm-alert/src/react-confirm-alert.css";
import "antd/dist/reset.css";
import { AppContext } from "../../context/AppContext";
import "primereact/resources/primereact.min.css";
import { useNavigate, useParams } from "react-router-dom";
import Utils from "../../utils/utils";
import toast, { Toaster } from "react-hot-toast";
import CropImage from "../../components/CropImage/CropImage";
function EditProfile() {
  const [dialogues, setDialogues] = useState(false);
  const [photoURL, setPhotoURL] = useState(null);
  const [file, setFile] = useState(null);
  const [editUserDetails, setEditUserDetails] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [openCrop, setOpenCrop] = useState(false);
  const { updateUser, userDetails, getUserDetails } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails != null) {
      setEditUserDetails(userDetails);
    }
  }, [userDetails]);

  useEffect(() => {
    validator(editUserDetails);
  }, [editUserDetails]);

  const closeCropModal = () => {
    setDialogues(false);
  };

  const updateUserDetails = async (e) => {
    e.preventDefault();
    const errors = validator(editUserDetails);
    console.log("errors", errors);
    setFormErrors(errors);
    if (Object.keys(errors).length == 0) {
      setFormErrors({});
      delete editUserDetails._id;
      const bodyFormData = Utils.createFormDataFromFormValues({
        ...editUserDetails,
      });
      if (file != null && file.name != null) {
        bodyFormData.append("file", file, file.name);
      }
      // debugger;
      updateUser(bodyFormData, {
        onSuccess: async (res) => {
          await getUserDetails();
          toast.success("User details updated successfully!");
          setTimeout(() => {
            navigate("/profile");
          }, 2000);
        },
        onFailed: (error) => {
          toast.error("Error updating user details!");
          console.log(error.message);
        },
      });
    }
  };

  const onChangeFormField = ({ name, value }) => {
    if (name.length == 0) return;
    let tempEditUserDetails = editUserDetails;
    tempEditUserDetails[name] = value;
    setEditUserDetails({ ...tempEditUserDetails });
  };
  const validator = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "This field cannot be blank";
    }
    if (!values.email) {
      errors.email = "This field cannot be blank";
    }
    return errors;
  };

  function onUploadImageClick() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      onFileSelect(event.target.files[0]);
    };
    input.click();
  }

  const onFileSelect = (imageFile) => {
    setPhotoURL(null);
    setFile(null);
    if (imageFile == null) return;
    setPhotoURL(URL.createObjectURL(imageFile));
    setOpenCrop(true);
  };

  console.log(editUserDetails.phonenumber);

  return (
    <>
      <Toaster position="top-center" />
      <header>
        <Header />
      </header>
      <main>
        <section class="edit-cont">
          <h2 className="text-center mx-auto mb-3">Update Profile</h2>

          <form
            onSubmit={(e) => updateUserDetails(e)}
            className="form-control add_form"
          >
            <div className="cropper">
              {openCrop && (
                <CropImage
                  {...{
                    photoURL,
                    setOpenCrop,
                    setPhotoURL,
                    setFile,
                    width: 350,
                    height: 350,
                    openCrop,
                  }}
                />
              )}
            </div>
            <Row>
              <Col>
                <div className="user-profile">
                  <div className="flex flex-column justify-content-center align-items-center">
                    <img
                      name="photo"
                      onClick={() => onUploadImageClick()}
                      className="image-pro"
                      src={
                        file != null
                          ? URL.createObjectURL(file)
                          : editUserDetails.photo != null
                            ? process.env.REACT_APP_AWS_IMAGE_URL +
                            editUserDetails.photo
                            : Profile
                      }
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                </div>
              </Col>
          
            </Row>

            <Row>
              <Col>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  className="form-control mb-3"
                  onChange={(e) => onChangeFormField(e.target)}
                  value={editUserDetails.name}
                />
                {formErrors.name && <p>{formErrors.name}</p>}
              </Col>
            </Row>
            <Row className="mb-3 mt-2">
              <Col sm={12} xl={6}>
                <label for="">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  onChange={(e) => onChangeFormField(e.target)}
                  value={editUserDetails.email}
                />
                {formErrors.email && <p>{formErrors.email}</p>}
              </Col>
              <Col sm={12} xl={6}>
                <label for="">Phone</label>
                <input
                  name="phonenumber"
                  className="form-control"
                  value={editUserDetails.phonenumber != null && editUserDetails.phonenumber}
                  onChange={(e) => onChangeFormField(e.target)}
                />
                {formErrors.phonenumber && <p>{formErrors.phonenumber}</p>}
              </Col>
            </Row>
            <Row className="mb-3 mt-3">
              <h6 className="mt-3 mb-3">Address</h6>
              <Col sm={12} xl={6}>
                <label for="">Flat / House number</label>
                <input
                  className="form-control"
                  name="addressline1"
                  value={editUserDetails.addressline1}
                  onChange={(e) => onChangeFormField(e.target)}
                />
              </Col>
              <Col sm={12} xl={6}>
                <label for="">Street/ Road name</label>
                <input
                  className="form-control"
                  name="addressline2"
                  value={editUserDetails.addressline2}
                  onChange={(e) => onChangeFormField(e.target)}
                />
              </Col>
            </Row>
            <Row className="mb-3 mt-3">
              <Col sm={12} xl={6}>
                <label for="">Town</label>
                <input
                  className="form-control"
                  name="town"
                  value={editUserDetails.town}
                  onChange={(e) => onChangeFormField(e.target)}
                />
              </Col>
              <Col xl={6}>
                <label for="">PostCode</label>
                <input
                  className="form-control"
                  value={editUserDetails.postalcode}
                  name="postalcode"
                  onChange={(e) => onChangeFormField(e.target)}
                />
              </Col>
            </Row>
            <Row className="mb-3 mt-3">
              <Col xl={6} sm={12}>
                <label for="">County</label>
                <input
                  className="form-control"
                  value={editUserDetails.county}
                  name="county"
                  onChange={(e) => onChangeFormField(e.target)}
                />
              </Col>
            </Row>

            <Row className="mx-auto text-center">
              <button type="submit" className="save-profile btn">
                Save
              </button>
            </Row>
          </form>
        </section>
      </main>
    </>
  );
}

export default EditProfile;
