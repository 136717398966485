import React from "react";
import { useNavigate } from "react-router";
import "./LiveSession.css";
import Lottie from "lottie-react";
import animationData from "../../assets/lottie/Breath.json";

function LeavePage() {
  const navigate = useNavigate();
  return (
    <>
      <section class="leavepage">
        <h1 className="text-center">You've left the meeting!</h1>
        <div class="lottie_area">
          <Lottie animationData={animationData} />
        </div>
        <div>
          <div className="box-msg mb-3">
            <p className="text-center">
              "You have been disconnected from the meeting."
              <br />
              If you experienced technical difficulties, please try to rejoin
              the meeting
              <br />
              Thank you for attending, and we hope to see you again soon.
            </p>
          </div>
        </div>
        <div class="btn_grp_">
          <button class="leave-btn rejoin" onClick={() => navigate(-1)}>
            Rejoin
          </button>
          <button
            class="leave-btn backtohome"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Go Back to Home
          </button>
        </div>
      </section>
    </>
  );
}

export default LeavePage;
