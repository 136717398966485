import React, { useEffect, useContext } from "react";
import "./Login.css";
import Login from "../../components/Login/Login";
import Signup from "../../components/Signup/Signup";
import { AppContext } from "../../context/AppContext";
import ConfirmPassword from "../ForgotPassword/ConfirmPassword";
import OTPVerification from "../../components/Signup/OTPVerification";
import PageNotFound from "../Errors/PageNotFound";
import { Navigate } from "react-router";

function Auth() {
  const { isSignup, setIsSignUp } = useContext(AppContext);

  console.log(isSignup);

  useEffect(() => {
    checkRegisteredDetails();
  }, []);

  const checkRegisteredDetails = () => {
    const registeredDetails =
      window.sessionStorage.getItem("RegisteredDetails");
    if (!registeredDetails) return;
    setIsSignUp("otp");
  };

  const goToLogin = () => setIsSignUp("login");
  const goToSignUp = () => setIsSignUp("signup");

  return (
    <>
      <section class="login_page">
        <div class="log_area">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="log_cont">
                <div class="log_logo">
                  <img src={require("../../assets/logo_main.png")} alt="" />
                </div>
                {isSignup == "signup" && (
                  <>
                    <Signup />
                    <p className="signup_link">
                      You already have an Account?{" "}
                      <a onClick={() => goToLogin()}>Login</a>
                    </p>
                  </>
                )}
                {isSignup == "login" && (
                  <>
                    <Login />
                    <p className="signup_link">
                      Don't have an account yet?{" "}
                      <a onClick={() => goToSignUp()}>Signup</a>
                    </p>
                    {/* <p className="gotohome">
                      Go to <a href="/">Home</a>
                    </p> */}
                  </>
                )}{" "}
                {isSignup == "otp" && <OTPVerification />}
                {!isSignup && <Navigate to="/pagenotfound" />}
                {/* <br/> */}
                <div className="or">
                  <span>OR</span>
                </div>
                <p id="home">
                  Go to <a href="/">Home</a>
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 log_img_main">
              <div class="log_img">
                <img src={require("../../assets/log_bgg.png")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Auth;
