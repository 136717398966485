import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Header/Header";
import Transitions from "../../Transition";
import "./Therapies.css";
import TherapyData from "./TherapyData";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import placeholder from "../../assets/nest_placeholder.jpg";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import BottomBar from "../../components/BottomBar/BottomBar";
import Footer from "../../components/Footer/Footer";
import { Row } from "react-bootstrap";

import { useNavigate } from "react-router";
import Modal from "./Modal";
import { AppContext } from "../../context/AppContext";
import Formatter from "../../helpers/Formatter";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Therapies() {
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState([]);
  const [active, setActive] = useState("all");
  const [viewMore, setViewMore] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTherapy, setSelectedTherapy] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const {
    therapies,
    allCategories,
    programsCollections,
    setTherapies,
    scheduledTherapiesLoading,
  } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    therapies.map((therapy) => {
      const categoryData = therapy.therapyDetails.category;
      // setCategories(JSON.parse(categoryData));
    });
    // console.log("THERAPIES", therapies);
    // console.log("programsCollections", programsCollections);
  }, [therapies]);

  useEffect(() => {
    listenCategoryChange();
  }, [selectedCategory]);

  const showMore = () => {
    setViewMore(!viewMore);
  };

  const checkoutDir = (id) => {
    navigate("/therapy-step/:id" + id);
  };

  const openTherapyDetailsModal = (therapy) => {
    if (!therapy) return;
    setSelectedTherapy(therapy);
    setOpenModal(true);
  };
  
  useEffect(() => {
    console.log("programsColls", allCategories);
  }, [allCategories]);

  const closeTherapyDetailsModal = () => {
    setSelectedTherapy(null);
    setOpenModal(false);
  };
  const listenCategoryChange = () => {
    if (selectedCategory == "all") {
      setTherapies(programsCollections);
      return;
    }

    if (selectedCategory == "popular") {
      const filteredPrograms = programsCollections.filter((program) => {
        return program != null && program.therapyDetails.popular;
      });
      setTherapies(filteredPrograms);
      return;
    }

    const filteredPrograms = programsCollections.filter((program) => {
      return (
        program != null &&
        program.therapyDetails.category._id == selectedCategory
      );
    });
    setTherapies(filteredPrograms);
  };

  const onCategoryClick = (id) => setSelectedCategory(id);

  const clickToRight = () => {
    const container = document.querySelector(".filteredTherapies");
    container.scrollLeft += 180;
  };
  const clickToLeft = () => {
    const container = document.querySelector(".filteredTherapies");
    container.scrollLeft -= 180;
  };

  return (
    <>
      <Transitions>
        {openModal && (
          <Modal
            open={openModal}
            selectedTherapy={selectedTherapy}
            onClose={() => closeTherapyDetailsModal()}
          />
        )}
        <header>
          <Header />
          {/* <section class="pro_banner">
            <div class="proBanner">
              <div class="container">
                <div class="pb_cont">
                  <h1 class="pro_title">Therapies</h1>
                  <p class="pro_sub">
                    All our Therapies are self-paced and have been designed by
                    subject matter experts, to give you an interactive and
                    enriched learning experience.
                  </p>
                </div>
                <div class="proBanner_sub_cont">
                  <h2>
                    Home <Bs.BsChevronDoubleRight /> Therapies
                  </h2>
                </div>
                <img
                  src={require("../../assets/bg-white.png")}
                  alt=""
                  class="banner_shape1"
                />
              </div>
            </div>
          </section> */}
        </header>
        <main>
          <section class="therapies">
            <div class="ev_bgg"></div>
            <div class="container">
              <h1
                class="page_head_title"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Our Therapies.
              </h1>
              <div
                class="therapy_cont"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="60"
                data-aos-duration="1000"
              >
                <div class="program_wrapper">
                  <div class="filter_item">
                    {allCategories.length > 4 && (
                      <button class="filter_nav" onClick={clickToLeft}>
                        <Ai.AiOutlineLeft />
                      </button>
                    )}
                    <ul className="filteredTherapies">
                      <li>
                        <button
                          className={
                            selectedCategory === "all"
                              ? "pro_btn active"
                              : "pro_btn"
                          }
                          onClick={() => onCategoryClick("all")}
                        >
                          All
                        </button>
                      </li>
                      <li>
                        <button
                          className={
                            selectedCategory === "popular"
                              ? "pro_btn active pop"
                              : "pro_btn pop"
                          }
                          onClick={() => onCategoryClick("popular")}
                        >
                          <img
                            src={require("../../assets/star.png")}
                            className="star"
                          />
                          Popular
                        </button>
                      </li>
                      {Array.isArray(allCategories) &&
                        allCategories.length != 0 &&
                        allCategories.map((category) => {
                          return (
                            <>
                              <li>
                                <button
                                  className={
                                    selectedCategory === category._id
                                      ? "pro_btn active"
                                      : "pro_btn"
                                  }
                                  onClick={() => onCategoryClick(category._id)}
                                >
                                  {category.category}
                                </button>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                    {allCategories.length > 4 && (
                      <button class="filter_nav right" onClick={clickToRight}>
                        <Ai.AiOutlineRight />
                      </button>
                    )}
                  </div>
                  {scheduledTherapiesLoading && (
                    <div className="card therapy_card mb-2">
                      <Row>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                          <div class="therapy_img">
                            <Skeleton height={150} />
                          </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-5">
                          <h1>
                            <Skeleton width={150} />
                          </h1>
                          <p>
                            <Skeleton width={100} />
                          </p>
                          <p>
                            <Skeleton count={2} />
                          </p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3">
                          <div className="buy_card">
                            <Skeleton className="buy_btn_load" />
                          </div>
                        </div>
                      </Row>
                    </div>
                  )}
                  <div class={viewMore ? "cover hide" : "cover"}>
                    {therapies &&
                      therapies.length > 0 &&
                      !scheduledTherapiesLoading &&
                      therapies.map((therapy, index) => {
                        const therapyDetails = therapy.therapyDetails;
                        // const categoryData = JSON.parse(
                        //   therapyDetails.category
                        // );

                        return (
                          <Row>
                            <div class="col-12 mb-2">
                              <div class="card therapy_card" key={index}>
                                <Row>
                                  <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="therapy_img">
                                      <div
                                        class="spinner-border img-spinner"
                                        role="status"
                                      ></div>
                                      <LazyLoadImage
                                        id="img_load"
                                        src={
                                          therapyDetails.photo != null
                                            ? process.env
                                                .REACT_APP_AWS_IMAGE_URL +
                                              therapyDetails.photo
                                            : require("../../assets/nest_placeholder.jpg")
                                        }
                                        effect="blur"
                                        width="100%"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-5 col-md-5 col-sm-5">
                                    <h1 class="therapy_title">
                                      {therapyDetails.therapyName}
                                    </h1>
                                    <p className="therapy_category">
                                      {/* {categoryData.category} */}
                                    </p>
                                    <p className="therapy_txt">
                                      {therapyDetails.therapyDescription}
                                    </p>
                                  </div>
                                  <div class="col-lg-3 col-md-3 col-sm-3">
                                    <div class="buy_card">
                                      <button
                                        className="buy_btn"
                                        onClick={() =>
                                          navigate(
                                            `/booking/${therapyDetails._id}`
                                          )
                                        }
                                      >
                                        Book now
                                      </button>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </Row>
                        );
                      })}
                  </div>
                  <div class="show_more">
                    <button type="button" onClick={showMore}>
                      {viewMore ? "Hide" : "Show more"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <BottomBar /> */}
        </main>
        <footer>
          <Footer />
        </footer>
      </Transitions>
    </>
  );
}

export default Therapies;
