import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import OTPInput, { ResendOTP } from "otp-input-react";
import "./style.css";
import useAuth from "../../hooks/useAuth";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import CustomMessages from "../../constants/Messages";
import { AppContext } from "../../context/AppContext";

function OTPVerification() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [registredData, setRegisteredData] = useState(null);
  const [message, setMessage] = useState(null);
  const { verifyUserOTP } = useAuth();
  const { setIsSignUp } = useContext(AppContext);
  useEffect(() => {
    const data = window.sessionStorage.getItem("RegisteredDetails");

    if (!data) return;
    setRegisteredData(JSON.parse(data));
  }, []);
  const handleOTP = async (e) => {
    e.preventDefault();
    if (!registredData || !otp) return;
    const payload = {
      userId: registredData.userId,
      otp: otp,
    };

    await verifyUserOTP(payload, {
      onSuccess: () => {
        setMessage(CustomMessages.USER_REGISTERED_SUCCESSFULLY);
        setTimeout(() => {
          setIsSignUp("login");
          navigate("/auth");
          window.sessionStorage.removeItem("RegisteredDetails");
        }, 1000);
      },
    });
  };
  return (
    <>
      {message && (
        <ToastContainer position="top-end" className="p-3">
          <Toast>
            <Toast.Header>
              <strong className="me-auto">
                {CustomMessages.USER_REGISTERED_SUCCESSFULLY}
              </strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <br />
      <div className="otp-box">
        <h3 className="text-center mt-2">OTP Verification</h3>
        <p className="otp-sub text-center">
          A One-Time-Password has been sent to{" "}
          {registredData != null && registredData.email != null
            ? registredData.email
            : registredData != null && registredData.phone != null
            ? registredData.phone
            : "Unknown"}
        </p>
        <form onSubmit={(e) => handleOTP(e)}>
          <div className="otp-area">
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
            />
          </div>
          <button className="form_btn verify">Verify</button>
        </form>
        <p className="resend-otp">
          Not received? <span>Resend OTP</span>
        </p>
      </div>
    </>
  );
}

export default OTPVerification;
