import React, { useState, useEffect, useContext } from "react";
import "./ForgotPassword.css";
import { Modal } from "antd";
import { AppContext } from "../../context/AppContext";
import CustomErrors from "../../constants/Errors";
import Utils from "../../utils/utils";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
function ForgotPassword() {
  const [showModal, setShowModal] = useState(true);
  const { forgotPassword, passwordloading } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const confirmMail = async (e) => {
    e.preventDefault();
    await forgotPassword(email, {
      onSuccess: (res) => {
        toast.success(res.message);
        sessionStorage.setItem("userId", res.userId);
        sessionStorage.setItem("email", email);
        navigate("/otp");
      },
      onFailed: (error) => {
        toast.error(error.message);
        switch (error.errorCode) {
          case "USER_NOT_FOUND":
            setError(CustomErrors.USER_NOT_FOUND);
            break;
          default:
            setError(CustomErrors.UNEXPECTED_ERROR);
        }
        console.log(error.message);
      },
    });
  };

  return (
    <>
     <Toaster position="top-center" />
      <Modal
        title="Tips"
        className="text-center"
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        onKeyDown={handleKeyDown}
        centered
      >
        <ul className="list-sty">
          <li>
            Please ensure that the email address is valid and connected to your
            Nest account.
          </li>
          <li>
            Please check your spam folder if you did not receive a password
            reset email from us.
          </li>
        </ul>
        <button
          className="form_btn close break"
          type="button"
          style={{ width: "30%" }}
          onClick={() => setShowModal(false)}>
          Ok
        </button>
      </Modal>

      <div className="container-fluid" style={{ position: "relative" }}>
        <div class="log_logo ali-logo">
          <img src={require("../../assets/logo_main.png")} alt="" />
        </div>
        <div className="card forgt-card">
          <div class="log_content card-pass-f">
            <h6 className="for-pass">Enter Email to Continue</h6>
            <p className="para-pass">
              Enter email address associated with your account
            </p>
            <form onSubmit={(e) => confirmMail(e)}>
              <div className="form-group">
                <label htmlFor="" className="form-label forget_label">
                  Email Address
                </label>
                <input
                  type="email"
                  class="form_field email"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  value={email}
                  required
                />
              </div>
              <button className="form_btn break" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className="tips">
          <ul>
            <li>
              <a href="#">Terms</a>
            </li>
            <li>
              <a href="#">Policy</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
            <li>
              <a href="#">About</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
