import React from "react";
import * as Ai from "react-icons/ai";
import { BsAlarm } from "react-icons/bs";

import "./Program.css";

function Modal({ open, onClose }) {
  if (!open) return null;
  return (
    <>
      <div class="modal_wrapper">
        <div class="modal_container">
          <div class="close" onClick={onClose}>
            <Ai.AiOutlineClose />
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="modal_img">
                <img
                  src={require("../../assets/assesments/lifeOccurrences/lifeOccurrences.jpg")}
                  alt=""
                />
                <div class="modal_btn d-flex">
                  <button className="buy">
                    <Ai.AiFillShopping /> Buy now
                  </button>
                  <button className="cart">
                    <Ai.AiOutlineShoppingCart />
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <div class="modal_cont">
                <h1>Medtiation Classes</h1>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Ipsum incidunt eaque, aliquid voluptatum libero nisi
                  reprehenderit suscipit. Repudiandae, obcaecati. Quidem dolor
                  officiis optio autem deserunt laboriosam omnis, nobis nulla
                  dolorum.
                </p>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod
                  eius odit, qui dignissimos mollitia dicta, adipisci nam magnam
                  ea quo rerum eligendi placeat? Dignissimos fugit qui ipsum?
                  Maxime, sapiente beatae!
                </p>
                <h4 className="price">
                  $19.99 <span>$199.99</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
