export default class Utils {
  static checkObjectAllValuesDefined(obj) {
    for (let prop in obj) {
      if (typeof obj[prop] === "undefined") {
        return false;
      }
    }
    return true;
  }

  static createFormDataFromFormValues(values) {
    const form = new FormData();

    Object.keys(values).forEach(function (key, index) {
      form.append(key, values[key]);
    });

    return form;
  }
  static checkStringIsNumber(value) {
    let str = value;
    let values = parseInt(str);

    if (!isNaN(values)) {
      console.log("original value", value);
      return values;
    }
  }

  static convertToNormalURL(url) {
    const base64Image = url.split(";base64,").pop();
    const blob = new Blob([atob(base64Image)], { type: "image/png" });
    return URL.createObjectURL(blob);
  }

  static createBlobFile(base64String) {
    const binaryData = atob(base64String.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    return new File([blob], "image.png");
  }

  static convertToLocalDate(date) {
    const localDate = new Date(date);

    const offsetMinutes = localDate.getTimezoneOffset();

    const utcDate = new Date(localDate.getTime() - offsetMinutes * 60000);

    const utcString = utcDate.toISOString();
    return utcString;
  }
}
