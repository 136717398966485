import React, { useState, useContext, useEffect } from "react";
import BaseClient from "../helpers/base_client";
import { APIEndpoints } from "../constants/ApiEndPoints";
import Utils from "../utils/utils";
import { SocketContext } from "../context/SocketContext";
import toast, { Toaster } from "react-hot-toast";
export default function useTheraphy() {
  const { socket } = useContext(SocketContext);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [therapies, setTherapies] = useState([]);
  const [bookedTherapies, setBookedTherpies] = useState([]);
  const [selectedDateSchedules, setSelectedDateSchedules] = useState([]);
  const [therapyLoading, setTherapyLoading] = useState(false);
  const [scheduledTherapiesLoading, setScheduledTherapiesLoading] =
    useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [programsCollections, setProgramsCollections] = useState([]);
  const [socketEvent, setSocketEvent] = useState(null);
  const [sessionStopped, setSessionStopped] = useState(false);
  const [bookedUsersList, setBookedUsersList] = useState([]);

  useEffect(() => {
    listenToSocket();
  }, [socket]);

  useEffect(() => {
    handleSocketEvents();
  }, [socketEvent]);

  /* scheduled theraphis category */
  const getTherphiesCategory = async () => {
    try {
      setLoading(true);
      await BaseClient.get(APIEndpoints.getCategories, {
        onSuccess: () => {},
        onFailed: () => {},
      });
    } finally {
      setLoading(false);
    }
  };

  /* scheduled theraphis list */
  const getScheduledTheraphis = async () => {
    try {
      setScheduledTherapiesLoading(true);
      await BaseClient.get(APIEndpoints.getScheduledTherapies, {
        onSuccess: (res) => {
          if (Array.isArray(res.data) && res.data.length > 0) {
            setTherapies(res.data);
            setProgramsCollections(res.data);
          }
        },
        onFailed: (error) => {
          console.log("error", error);
          setTherapies([]);
          setProgramsCollections([]);
        },
      });
    } finally {
      setScheduledTherapiesLoading(false);
    }
  };

  const getBookedScheduleOfUser = async () => {
    try {
      setLoading(true);
      await BaseClient.get(APIEndpoints.getBookedScheduleOfUser, {
        onSuccess: (res) => {
          if (!Array.isArray(res.result.bookings)) return;
          setBookedTherpies(res.result.bookings);
        },
        onFailed: (error) => {
          console.log("ERROR", error.message);
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getUserListOfSchedule = async (bookingId) => {
    await BaseClient.get(APIEndpoints.getScheduledUsersList, {
      onSuccess: (res) => {
        if (!Array.isArray(res.result.bookings)) return;
        const bookingDetails = res.result.bookings.filter(
          (schedule) => schedule._id == bookingId
        );
        console.log("UserDetails", bookingDetails[0].userDetails);
        setBookedUsersList(bookingDetails[0].userDetails);
      },
      onFailed: (err) => {
        console.log(err.message);
      },
    });
  };

  const findScheduleByDate = async ({ date, therapyId }) => {
    try {
      setTherapyLoading(true);
      await BaseClient.post(
        APIEndpoints.findScheduleByDate,
        { date: Utils.convertToLocalDate(date), therapyId },
        {
          onSuccess: (res) => {
            console.log("Res", res);
            if (Array.isArray(res.result)) {
              setSelectedDateSchedules(res.result);
            }
          },
          onFailed: (error) => {
            console.log("error", error);
            setSelectedDateSchedules([]);
          },
        }
      );
    } finally {
      setTherapyLoading(false);
    }
  };

  const listenToSocket = () => {
    if (!socket) return;
    socket.on("session-started", (data) => {
      setSocketEvent({ event: "session-started", data });
    });

    socket.on("session-stopped", (data) => {
      setSocketEvent({ event: "session-stopped", data });
    });

    socket.onAny((event) => {
      console.log(`ANY ${event}`);
    });
  };

  const handleSocketEvents = async () => {
    if (!socketEvent || !socketEvent.event) return;

    switch (socketEvent.event) {
      case "session-started":
        console.log("start triggered", socketEvent.data);
        await getBookedScheduleOfUser();

        const data = socketEvent.data;

        const id = data["_id"];
        const userIds = data["userIds"];
        const date = data["date"];
        const scheduleId = data["scheduleId"];
        const bookingStatus = data["bookingStatus"];
        const streamStatus = data["streamStatus"];
        const dateSchedule = data["dateSchedule"];
        const userDetails = bookedTherapies[0].userDetails;
        const week = bookedTherapies[0].week;
        const scheduleDetails = data["scheduleDetails"];
        const theraphyDetails = bookedTherapies[0].theraphyDetails;
        const therapistId = bookedTherapies[0].therapistId;
        const therapistDetails = data["therapistDetails"];

        const newData = {
          _id: id,
          userIds,
          date,
          scheduleId,
          bookingStatus,
          streamStatus,
          dateSchedule,
          userDetails,
          week,
          scheduleDetails,
          theraphyDetails,
          therapistId,
          therapistDetails,
        };
        updateTherapyVideo(newData);
        break;

      case "session-stopped":
        console.log("stop triggered");
        getBookedScheduleOfUser();
        setSessionStopped(true);
        break;
    }
  };

  const updateTherapyVideo = (newData) => {
    if (!newData) return;

    let locIndex = bookedTherapies.findIndex((x) => x._id == newData.id);

    if (locIndex == -1) {
      setBookedTherpies((prev) => [...prev, newData]);
      return;
    }

    setBookedTherpies((prev) => {
      const updatedTherapies = [...prev];
      updatedTherapies[locIndex] = newData;
      return updatedTherapies;
    });
  };

  return {
    getTherphiesCategory,
    getScheduledTheraphis,
    therapies,
    categories,
    getBookedScheduleOfUser,
    bookedTherapies,
    setBookedTherpies,
    findScheduleByDate,
    selectedDateSchedules,
    therapyLoading,
    loading,
    disableButton,
    setDisableButton,
    setTherapies,
    programsCollections,
    scheduledTherapiesLoading,
    sessionStopped,
    getUserListOfSchedule,
    bookedUsersList,
  };
}
