// Packages
import React, { useState, useEffect } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Card, Button, Row } from "react-bootstrap";
import Slider from "react-slick";
import Lottie from "lottie-react";
import Aos from "aos";
import { useNavigate } from "react-router";

// Components
import HealthAnomation from "../../assets/lottie/43792-yoga-se-hi-hoga.json";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header2/Header2";
import BottomBar from "../../components/BottomBar/BottomBar";
import Transitions from "../../Transition";
import Popup from "../../components/Popup/Popup";

// icons
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Fa from "react-icons/fa";
import * as Bi from "react-icons/bi";
import * as Md from "react-icons/md";
import * as Im from "react-icons/im";

// css
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";

function Home() {
  Aos.init();

  const navigate = useNavigate();
  const [panel, setPanel] = useState("1");
  const [videoUp, setVideoUp] = useState(false);
  const [list, setList] = useState([]);

  const panelChange = (event, newPanel) => {
    setPanel(newPanel);
  };
  var sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Transitions>
        {/* header Starts */}
        <header>
          <Header />
          <section className="banner">
            <Container>
              <div className="banner-sec">
                <div
                  className="lottie_bg"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <Lottie animationData={HealthAnomation}></Lottie>
                </div>
                <div className="banner-cont">
                  <h1 data-aos="fade-up" data-aos-duration="2000">
                    Changing lives
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="1500">
                    <span>Tailor-made therapies for your wellbeing</span> <br />
                    When you choose Nest Healthcare you’re choosing an
                    exclusive, specialist rehabilitation and wellness clinic.
                    This gives you access to an exceptional blend of
                    complementary and clinical therapies. Every person is unique
                    and that means that every course of treatment or therapy is
                    individually tailored to your needs. You’ll start with an
                    expert assessment and our private boutique environment
                    provides a calm and supportive atmosphere where you can
                    relax
                  </p>
                </div>
                <div class="" data-aos="fade-up" data-aos-duration="2000">
                  <button
                    className="course_btn"
                    onClick={() => navigate("/therapies")}
                  >
                    Start a Therapy <Ai.AiOutlineRight />
                  </button>
                </div>
              </div>
             
              <h1
                class="subb_title"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                What We Do
              </h1>
              <div className="row c_row">
                <div className="col-lg-4 col-md-4.col-sm-4 col_c">
                  <div
                    className="card banner-items"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <div className="bc_img">
                      <img src={require("../../assets/ICO/plant.png")} />
                    </div>
                    <p>Identify the Root Cause</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4.col-sm-4 col_c">
                  <div
                    className="card banner-items"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <div className="bc_img">
                      <img src={require("../../assets/ICO/medication.png")} />
                    </div>
                    <p>Building a Personalised & Responsive Treatment Plan</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4.col-sm-4 col_c">
                  <div
                    className="card banner-items"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                  >
                    <div className="bc_img">
                      <img src={require("../../assets/ICO/spa.png")} />
                    </div>
                    <p>Effective aftercare & Long-term recovery</p>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </header>
        {/* Header End */}
        {/* Main Content Start */}
        <main>
          <section className="programmes">
            <Container>
              <h1
                className="program_title"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                A positive place for your healing journey
              </h1>
              <p
                className="program_sub"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Whatever mental health condition you may be suffering from you
                can rely on our help to understand the complexity, fragility and
                devastating effects mental health can have on a person’s life.
              </p>

              <div className="p_card">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img src={require("../../assets/programme/pro1.jpg")} />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Career development</h5>
                        <p class="card-text">
                        Career development is a dynamic process that empowers individuals to shape their professional journeys. 
                        </p>
                        <a
                          href="/therapies"
                          className="know_more"
                          title="Know more"
                        >
                          {" "}
                          <i>
                            <Bs.BsFillArrowRightSquareFill />
                            <span>know more</span>
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-delay="60"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img src={require("../../assets/programme/pro2.jpg")} />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>

                      <div class="card-body proCard">
                        <h5 class="card-title">Life coaching</h5>
                        <p class="card-text">
                          Life coaching is a dynamic and empowering partnership
                          that aims to guide individuals.
                        </p>
                        <a
                          href="/therapies"
                          className="know_more"
                          title="Know more"
                        >
                          {" "}
                          <i>
                            <Bs.BsFillArrowRightSquareFill />
                            <span>know more</span>
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div
                      class="card pro_card1"
                      data-aos="fade-left"
                      data-aos-offset="200"
                      data-aos-delay="70"
                      data-aos-duration="1000"
                    >
                      <div className="card-img-top">
                        <img src={require("../../assets/programme/pro3.jpg")} />
                        <div className="overlay">
                          <span className="vline"></span>
                          <span className="vline-bottom"></span>
                        </div>
                      </div>
                      <div class="card-body proCard">
                        <h5 class="card-title">Meditation</h5>
                        <p class="card-text">
                        Meditation is a powerful practice that offers a sanctuary for the mind in our fast-paced world
                        </p>
                        <a
                          href="/therapies"
                          className="know_more"
                          title="Know more"
                        >
                          {" "}
                          <i>
                            <Bs.BsFillArrowRightSquareFill />
                            <span>know more</span>
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          {/* <section className="trial">
            <Container>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div
                    className="form_area"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  >
                    <p>
                      Online Programmes <span>for Free</span>
                    </p>
                    <h2>START MY FREE MONTH</h2>
                    <h4>Stay Sharp. Get ahead with Learning Paths.</h4>
                    <div className="container">
                      <form className="trial_form">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-field"
                            placeholder="Your Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="mail"
                            className="form-field"
                            placeholder="Your Email address"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-field"
                            placeholder="Your Phone number"
                          />
                        </div>
                        <button class="form_btn">APPLY NOW</button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <Box
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1500"
                  >
                    <TabContext value={panel}>
                      <Box
                        sx={{
                          borderBottom: "1px",
                          borderColor: "darkolivegreen",
                        }}
                      >
                        <TabList aria-label="Tabs" onChange={panelChange}>
                          <Tab
                            className="programPanel"
                            label="FACILITY"
                            value="1"
                          />
                          <Tab
                            className="programPanel"
                            label="E-LEARNING"
                            value="2"
                          />
                          <Tab
                            className="programPanel"
                            label="VOVABULARY"
                            value="3"
                          />
                          <Tab
                            className="programPanel kidProgram"
                            label="Kid's Program"
                            value="4"
                          />
                        </TabList>
                        <TabPanel className="panel_cont" value="1">
                          <Container>
                            <Popup trigger={videoUp} setTrigger={setVideoUp} />

                            <div className="panel_img">
                              <button
                                class="video_layer"
                                onClick={() => setVideoUp(true)}
                              >
                                <Ai.AiFillPlayCircle />
                              </button>
                              <img
                                src={require("../../assets/video/video.png")}
                              />
                            </div>
                            <p className="panel_cont">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.Lorem Ipsum is simply dummy text of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever since
                              the 1500s, when an unknown printer took a galley
                              of type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged.
                            </p>
                          </Container>
                        </TabPanel>
                        <TabPanel className="panel_cont" value="2">
                          Panel Two
                        </TabPanel>
                        <TabPanel className="panel_cont" value="3">
                          Panel three
                        </TabPanel>
                        <TabPanel className="panel_cont" value="4">
                          Panel four
                        </TabPanel>
                      </Box>
                    </TabContext>
                  </Box>
                </div>
              </div>
            </Container>
          </section> */}
          {/* <section className="instructor">
            <Container>
              <h1
                className="program_title"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                Our Skilled Instructors
              </h1>
              <p
                className="program_sub"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Smply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry’s standard dummy text ever since the
                1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book.
              </p>
              <Row>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card"
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="crImg">
                      <img
                        src={require("../../assets/instructors/instructor-1.png")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Aleksejs Petenko</h5>
                      <span>Teacher</span>
                      <p class="card-text">
                        Lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
                        facilisis vel mollis vitae.
                      </p>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div className="social d-flex">
                          <a href="/">
                            <Fa.FaFacebookF />
                          </a>
                          <a href="/">
                            <Bs.BsTwitter />
                          </a>
                          <a href="/">
                            <Fa.FaLinkedinIn />
                          </a>
                          <a href="/">
                            <Bs.BsInstagram />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="crImg">
                      <img
                        src={require("../../assets/instructors/instructor-2.png")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Anna Kunytska</h5>
                      <span>Teacher</span>
                      <p class="card-text">
                        Lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
                        facilisis vel mollis vitae.
                      </p>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div className="social d-flex">
                          <a href="/">
                            <Fa.FaFacebookF />
                          </a>
                          <a href="/">
                            <Bs.BsTwitter />
                          </a>
                          <a href="/">
                            <Fa.FaLinkedinIn />
                          </a>
                          <a href="/">
                            <Bs.BsInstagram />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card ins_card"
                    data-aos="fade-left"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="crImg">
                      <img
                        src={require("../../assets/instructors/instructor-3.png")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Ben Daniel</h5>
                      <span>Teacher</span>
                      <p class="card-text">
                        Lobortis arcu, a vestibulum augue. Vivamus ipsum neque,
                        facilisis vel mollis vitae.
                      </p>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div className="social d-flex">
                          <a href="/">
                            <Fa.FaFacebookF />
                          </a>
                          <a href="/">
                            <Bs.BsTwitter />
                          </a>
                          <a href="/">
                            <Fa.FaLinkedinIn />
                          </a>
                          <a href="/">
                            <Bs.BsInstagram />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Row>
            </Container>
          </section> */}
          {/* <section class="events">
            <Container>
              <h1
                className="program_title"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                Upcoming Events
              </h1>
              <p
                className="program_sub"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry’s standard dummy text ever
                since the 1500s.
              </p>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card event_card"
                    data-aos="zoom-in-left"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="eImg">
                      <img
                        src={require("../../assets/events/event1.jpg")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-8" style={{ padding: "20px" }}>
                          <h5 class="card-title">TED Talk</h5>
                          <div class="d-flex">
                            <span>
                              <i>
                                <Bi.BiTime />
                              </i>{" "}
                              07:10 PM
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              <i>
                                <Md.MdOutlineLocationOn />
                              </i>{" "}
                              Melbourne
                            </span>
                          </div>
                        </div>
                        <div class="col-4" style={{ padding: "0" }}>
                          <div class="ev_date">
                            <h1>8</h1>
                            <h2>June</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card event_card"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="eImg">
                      <img
                        src={require("../../assets/events/event3.jpg")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-8" style={{ padding: "20px" }}>
                          <h5 class="card-title">
                            Conference and Dinner with Our Clients
                          </h5>
                          <div class="d-flex">
                            <span>
                              <i>
                                <Bi.BiTime />
                              </i>{" "}
                              07:10 PM
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              <i>
                                <Md.MdOutlineLocationOn />
                              </i>{" "}
                              Dubai
                            </span>
                          </div>
                        </div>
                        <div class="col-4" style={{ padding: "0" }}>
                          <div class="ev_date">
                            <h1>11</h1>
                            <h2>Aug</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <div
                    class="card event_card"
                    data-aos="zoom-in-right"
                    data-aos-offset="200"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                  >
                    <div className="eImg">
                      <img
                        src={require("../../assets/events/event2.jpg")}
                        class="card-img-top"
                      />
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-8" style={{ padding: "20px" }}>
                          <h5 class="card-title">
                            Crash Course for Senior sitizen
                          </h5>
                          <div class="d-flex">
                            <span>
                              <i>
                                <Bi.BiTime />
                              </i>{" "}
                              11:00 AM
                            </span>
                            <span style={{ paddingLeft: "20px" }}>
                              <i>
                                <Md.MdOutlineLocationOn />
                              </i>{" "}
                              Newyork
                            </span>
                          </div>
                        </div>
                        <div class="col-4" style={{ padding: "0" }}>
                          <div class="ev_date">
                            <h1>20</h1>
                            <h2>May</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section> */}
          {/* <section class="teachers">
            <div class="container">
              <h1
                className="program_title"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                What Our Teacher’s Say
              </h1>
              <p
                className="program_sub pb-10"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="40"
                data-aos-duration="1000"
              >
                Simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry’s standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </p>
              <div
                class="zoom-out-down"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="60"
                data-aos-duration="1000"
              >
                <Slider {...sliderSettings}>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-1.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">Edwin Kimro</h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-2.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">
                            Jaquline Fernadez
                          </h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-4.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">Harsha Bogal</h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-5.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">
                            Thomas Piatro
                          </h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-6.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">
                            William Rodwik
                          </h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slick_slide">
                    <div class="card slicks">
                      <div class="row">
                        <div class="col-4" style={{ position: "relative" }}>
                          <div class="slick-img">
                            <img
                              src={require("../../assets/instructors/instructor-3.png")}
                              alt="teachers"
                            />
                          </div>
                          <div class="slick_logo">
                            <a href="/">
                              <Fa.FaFacebookF />
                            </a>
                            <a href="/">
                              <Bs.BsTwitter />
                            </a>
                            <a href="/">
                              <Fa.FaLinkedinIn />
                            </a>
                            <a href="/">
                              <Bs.BsInstagram />
                            </a>
                          </div>
                        </div>
                        <div class="col-8 p-2 slickss">
                          <h2 className="slick_name text-left">
                            Alexandra jermiah maxie
                          </h2>
                          <p className="text-sm leading-tight">Teacher</p>
                          <p className="slick_card-cont">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Quo ea rerum, quam similique obcaecati
                            assumenda a. Nisi cupiditate atque praesentium sint
                            delectus facilis vero quo! Facilis nobis unde
                            voluptate accusantium.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section> */}
          <BottomBar />
        </main>
        {/* main content End */}
        {/* Footer starts */}
        <footer>
          <Footer />
        </footer>
      </Transitions>
    </>
  );
}

export default Home;
