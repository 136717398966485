import React, { useEffect, useState } from "react";
import "./BackToTop.css";

import * as Bi from "react-icons/bi";

function BackToTop() {
  const [backTop, setBackTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 250) {
        setBackTop(true);
      } else{
        setBackTop(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      { backTop && (<div className="back2top" onClick={scrollUp}>
        <Bi.BiArrowToTop />
      </div>)}
    </>
  );
}

export default BackToTop;
