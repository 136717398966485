import React from "react";
import { useNavigate } from "react-router";
import "./Error.css";
import Lottie from "lottie-react";
import animatedLotties from "../../assets/lottie/error.json";

function StreamErrors(props) {
  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <>
      <div className="container">
        <div className="mx-auto">
          <h6 className="error-head text-center">{props.title}</h6>
          <div
            className="lottie-area"
            data-aos="fade-up"
            data-aos-duration="300"
          >
            <Lottie animationData={animatedLotties} loop={true}></Lottie>
          </div>
          <p className="text-center ero_msg">{props.content}</p>
       <div className="mx-auto text-center">
       <button type="button" class="btn btn-dark" onClick={reloadPage}>Retry</button>
       <button type="button" class="btn btn-outline-dark" data-mdb-ripple-color="dark" onClick={() => (window.location.href = "/")}>Home</button>
       </div>
       
        </div>
      </div>
    </>
  );
}

export default StreamErrors;
